// @mui
import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
// utils
import cssStyles from "src/utils/cssStyles";
// components
import Image from "src/components/Image";

import axios from "src/utils/axios";
import useAuth from "src/hooks/useAuth";
import LeaveDialog from "../../../../sections/@dashboard/manage/TeamView/LeaveDialog";
import { useState } from "react";
import { useSnackbar } from "notistack";
import Avatar from "src/components/Avatar";
import JoinTeamDialog from "src/components/dialog/JoinTeamDialog";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  "&:before": {
    ...cssStyles().bgBlur({ blur: 2, color: theme.palette.primary.darker }),
    top: 0,
    zIndex: 9,
    content: "''",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
}));

const InfoStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: "absolute",
  marginTop: theme.spacing(5),
  [theme.breakpoints.up("md")]: {
    right: "auto",
    display: "flex",
    alignItems: "center",
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

export default function PublicTeamCover({
  title = "",
  subtitle = "",
  profile = "",
  cover = "",
  id = "",
  teamId = "",
  players = [],
}) {
  let { user } = useAuth();

  const [open, setOpen] = useState({ open: false, func: null });
  const [openRequestToJoin, setOpenRequestToJoin] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleLeaveTeam = () => {
    setOpen({ open: true, func: leaveTeam });
  };

  const handleRequestJoinTeam = () => {
    setOpenRequestToJoin(true);
  };

  const leaveTeam = async () => {
    try {
      await axios.get(`/teams/${id}/leave/${user._id}`);
      setOpen({ open: false, func: null });
      enqueueSnackbar("Successfully left team.", {
        autoHideDuration: 4000,
        variant: "success",
      });
      window.location.replace("/dashboard/player");
    } catch (e) {
      enqueueSnackbar("Error leaving team. Please try again.", {
        autoHideDuration: 4000,
        variant: "error",
      });
    }
  };

  function userOnTeam(players, userId) {
    // Check if the team has players and filter the ones with matching userId
    if (players && Array.isArray(players)) {
      return players.some((player) => player.userId._id === userId);
    }
    return false;
  }
  return (
    <RootStyle>
      <InfoStyle
        sx={{
          width: { md: "calc(100% - 50px)" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyItems: "start",
        }}
      >
        <Avatar
          sx={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "common.white",
            width: { xs: 80, md: 128 },
            height: { xs: 80, md: 128 },
          }}
          src={profile}
          name={title}
        />

        <Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            color: "common.white",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          {title && <Typography variant="h4">{title} </Typography>}
          {subtitle && (
            <Typography sx={{ opacity: 0.72 }}>{subtitle}</Typography>
          )}
        </Box>

        {user.goals &&
          (user.goals?.isPlayerLookingForOtherPlayersToJoinNonProTeamOrToPlay ||
            user.goals?.isPlayerLookingForProTeam ||
            user.goals?.isPlayerAlreadyOnProTeam ||
            user.goals?.isPlayerLookingForCollegeTeam ||
            user.goals?.isPlayerAlreadyOnCollegeTeam ||
            user.goals?.isPlayerLookingForPlayersToPlayInTournament) && (
            <>
              {userOnTeam(players, user._id) ? (
                <Button
                  sx={{ ml: { md: "auto" }, mt: { xs: 2, md: 0 } }}
                  variant="contained"
                  onClick={handleLeaveTeam}
                >
                  Leave This Team
                </Button>
              ) : (
                <Button
                  sx={{ ml: { md: "auto" }, mt: { xs: 2, md: 0 } }}
                  variant="contained"
                  onClick={handleRequestJoinTeam}
                >
                  Request to join team
                </Button>
              )}
            </>
          )}
      </InfoStyle>
      <Image
        alt="profile cover"
        src={cover}
        sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
      />

      <JoinTeamDialog
        open={openRequestToJoin}
        handleClose={() => setOpenRequestToJoin(false)}
        name={title}
        teamId={teamId}
      />
      <LeaveDialog name={title} open={open} setOpen={setOpen} />
    </RootStyle>
  );
}

import PropTypes from 'prop-types';
import ProfileSocialInfo from '../ProfileSocialInfo';
import PlayerProfileAbout from './PlayerProfileAbout';

PlayerProfileInfo.propTypes = {
    profile: PropTypes.object,
};

export default function PlayerProfileInfo({ profile }) {
    return (
        <>
            <PlayerProfileAbout profile={profile} />
            <ProfileSocialInfo profile={profile} />
        </>
    );
}

import React from "react";
import { Box, Typography } from "@mui/material";
import { RHFTextField } from "src/components/hook-form";

import LocationCard from "./Location";
import { Controller } from "react-hook-form";
import { MuiTelInput } from "mui-tel-input";

const LocationAndContactInfo = ({ values, control, errors }) => {
  return (
    <>
      <LocationCard
        city={values.locationCity}
        state={values.locationState}
        country={values.locationCountryCode}
      />
      <Typography variant="h6" sx={{ fontWeight: "500", mt: 3, mb: 2, ml: 1 }}>
        Contact Info
      </Typography>
      <Box
        sx={{
          display: "grid",
          rowGap: 3,
          columnGap: 2,
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
          },
        }}
      >
        <RHFTextField name="email" label="Email" />
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <>
              <MuiTelInput
                name="phone"
                defaultCountry="US"
                {...field}
                label="Phone no."
                helperText={errors.phone?.message || ""}
                error={!!errors.phone?.message}
              />
            </>
          )}
        />
        <RHFTextField name="website" label="Website" />
        <RHFTextField name="discord" label="Discord Group" />
      </Box>
      <Typography variant="h6" sx={{ fontWeight: "500", mt: 3, mb: 2, ml: 1 }}>
        Teams
      </Typography>
      <Box
        sx={{
          display: "grid",
          rowGap: 3,
          columnGap: 2,
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
          },
        }}
      >
        <RHFTextField
          name="totalNumberOfTeamsInOrganization"
          label="Total number of teams"
          type="number"
        />
        <RHFTextField
          name="totalNumberOfPlayersInOrganization"
          label="Total number of players"
          type="number"
        />
      </Box>
    </>
  );
};

export default LocationAndContactInfo;

import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { ListItemText, ListItemIcon, ListItemButton, Stack, Box, Chip, Typography } from '@mui/material';
// routes
// components
import { useMemo } from 'react';
import Avatar from 'src/components/Avatar';
import { formatDate } from 'fullcalendar';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

MailSidebarItem.propTypes = {
  label: PropTypes.object.isRequired,
};

export default function MailSidebarItem({ createdAt, label, icon, status, select, selected, type }) {
  const statusColor = useMemo(() => {
    switch (status) {
      case 'Pending':
        return 'warning';
      case 'Accepted':
        return 'success';
      case 'Rejected':
        return 'error';
      default:
        return 'default';
    }
  }, [status])
  return (
    <ListItemButton
      onClick={select}
      component={RouterLink}
      sx={{
        p: 3,
        typography: 'body2',
        color: 'text.secondary',
        '&.active': {
          color: 'text.primary',
          fontWeight: 'fontWeightMedium',
          ...(selected && { bgcolor: 'action.selected', })
        },
      }}
    >
      <Stack alignItems={"center"} direction={"row"}>
        <ListItemIcon>
          <Avatar src={icon || ""} width={30} height={30} name={label}>
          </Avatar>
        </ListItemIcon>
        <Box>
          <ListItemText disableTypography primary={label} sx={{ mb: .25 }} />
          <Box sx={{ pb: .5 }}>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {createdAt && formatDate(createdAt)}
            </Typography>
          </Box>

          <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
            <Chip label={type} size="small" sx={{ mr: 1 }} />
            <Chip label={status} size="small" color={statusColor} />
          </Stack>
        </Box>
      </Stack>
    </ListItemButton>
  );
}



export  function InviteRequestItem({ invite: { createdAt, relatedTeam, status, type }, select, selected }) {
  const statusColor = useMemo(() => {
    switch (status) {
      case 'Pending':
        return 'warning';
      case 'Accepted':
        return 'success';
      case 'Rejected':
        return 'error';
      case 'Revoked':
        return 'error';
      default:
        return 'default';
    }
  }, [status])
  return (
    <ListItemButton
      onClick={select}
      component={RouterLink}
      sx={{
        p: 3,
        typography: 'body2',
        color: 'text.secondary',
        '&.active': {
          color: 'text.primary',
          fontWeight: 'fontWeightMedium',
          ...(selected && { bgcolor: 'action.selected', })
        },
      }}
    >
      <Stack alignItems={"center"} direction={"row"}>
        <ListItemIcon>
          <Avatar src={relatedTeam?.avatarUrl || ""} width={30} height={30} name={relatedTeam.name}>
          </Avatar>
        </ListItemIcon>
        <Box>
          <ListItemText disableTypography primary={relatedTeam.name} sx={{ mb: .25 }} />
          <Box sx={{ pb: .5 }}>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {createdAt && formatDate(createdAt)}
            </Typography>
          </Box>

          <Stack direction={"row"} alignItems={"center"} >
            <Chip label={type} size="small" sx={{ mr: 1 }} />
            <Chip label={status} size="small" color={statusColor} />
          </Stack>
        </Box>
      </Stack>
    </ListItemButton>
  );
}

export function JoinRequestItem({ join: { status, sender, createdAt, type }, select, selected }) {
  const statusColor = useMemo(() => {
    switch (status) {
      case 'Pending':
        return 'warning';
      case 'Accepted':
        return 'success';
      case 'Rejected':
        return 'error';
      case 'Revoked':
        return 'error';
      default:
        return 'default';
    }
  }, [status])
  return (
    <ListItemButton
      onClick={select}
      component={RouterLink}
      sx={{
        p: 3,
        typography: 'body2',
        color: 'text.secondary',
        '&.active': {
          color: 'text.primary',
          fontWeight: 'fontWeightMedium',
          ...(selected && { bgcolor: 'action.selected', })
        },
      }}
    >
      <Stack alignItems={"center"} direction={"row"}>
        <ListItemIcon>
          <Avatar src={sender?.userId?.username} width={30} height={30} name={sender?.userId?.username}>
          </Avatar>
        </ListItemIcon>
        <Box>
          <ListItemText disableTypography primary={sender?.userId?.username || "Own"} sx={{ mb: .25 }} />
          <Box sx={{ pb: .5 }}>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {createdAt && formatDate(createdAt)}
            </Typography>
          </Box>

          <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
            <Chip label={type} size="small" sx={{ mr: 1 }} />
            <Chip label={status} size="small" color={statusColor} />
          </Stack>
        </Box>
      </Stack>
    </ListItemButton>
  );
}

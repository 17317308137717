import orderBy from "lodash/orderBy";
import React, { useEffect, useState } from "react";
// @mui
import {
  Box,
  Grid,
  Stack,
  Input,
  Typography,
  InputAdornment,
} from "@mui/material";

// components
import Iconify from "src/components/Iconify";
import { SkeletonPostItem } from "src/components/skeleton";
// sections
import { TeamCard, TeamsSortNew } from "src/sections/@dashboard/manage";

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: "Name", label: "Name" },
  { value: "Last Edited", label: "Last Edited" },
];

const applySort = (teams, sortBy) => {
  let parsedTeams = teams.map(team => ({...team, loweredCaseName: team.name.toLowerCase(), lastEditedTimeStamp: new Date(team.lastEdited).getTime()}))
  if (sortBy === "Name") {
    return orderBy(parsedTeams, ["loweredCaseName"]);
  }
  if (sortBy === "Last Edited") {
    return orderBy(parsedTeams, ["lastEditedTimeStamp"], ["desc"]);
  }

  return teams;
};

export default function PlayerTeams({teams, isPublicProfile=false}) {

  const [filters, setFilters] = useState("Name");
  const [searchVal, setSearchVal] = useState("");
  const [sortedTeams, setSortedTeams] = useState(applySort(teams, filters));

  useEffect(() => {
    if (!searchVal.trim()) setSortedTeams(applySort(teams, filters));
    else
      setSortedTeams(
        sortedTeams.filter((team) => team.name.includes(searchVal))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal]);

  const handleChangeSort = (value) => {
    if (value) {
      setFilters(value);
      setSortedTeams(applySort(teams, value));
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Input
          value={searchVal}
          disableUnderline
          onChange={(event) => setSearchVal(event.target.value)}
          placeholder="Search teams..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify
                icon={"eva:search-fill"}
                sx={{ width: 20, height: 20, color: "text.disabled" }}
              />
            </InputAdornment>
          }
          sx={{
            border: "1px solid #ddd3",
            borderRadius: "8px",
            py: 1,
            px: 2,
            fontSize: "14px",
          }}
        />

        <TeamsSortNew
          query={filters}
          options={SORT_OPTIONS}
          onSort={handleChangeSort}
        />
      </Stack>

      <Grid container spacing={3} sx={{ mt: 2 }}>
        {sortedTeams.length
          ? sortedTeams.map((team, index) =>
              team ? (
                <Grid key={team._id} item xs={12} sm={6} md={4}>
                  <TeamCard isPublicProfile={isPublicProfile} team={team} />
                </Grid>
              ) : (
                <SkeletonPostItem key={index} />
              )
            )
          : ""}
      </Grid>

    

      {!teams.length &&  (
        <Typography variant="subtitle1" sx={{ textAlign: "center", mt: 2 }}>
          This player is not a member of any teams.
        </Typography>
      )}
      {teams.length > 0 && !sortedTeams.length  && (
        <Typography variant="subtitle1" sx={{ textAlign: "center", mt: 2 }}>
          No teams found.
        </Typography>
      )}
    </Box>
  );
}

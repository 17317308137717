import { useState } from "react";
// @mui
import { Button, MenuItem, Typography } from "@mui/material";
// components
import Iconify from "src/components/Iconify";
import MenuPopover from "src/components/MenuPopover";

// ----------------------------------------------------------------------

export default function SquadsSortNew({ query, options, onSort }) {
  const [open, setOpen] = useState(null);

  const handleOpen = (currentTarget) => {
    setOpen(currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleSortBy = (value) => {
    onSort(value);
    handleClose();
  };

  return (
    <>
      <Button
        color="inherit"
        disableRipple
        onClick={(event) => handleOpen(event.currentTarget)}
        endIcon={
          <Iconify
            icon={open ? "eva:chevron-up-fill" : "eva:chevron-down-fill"}
          />
        }
      >
        Sort By: &nbsp;
        <Typography
          component="span"
          variant="subtitle2"
          sx={{ color: "text.secondary" }}
        >
          {query}
        </Typography>
      </Button>

      <MenuPopover
        anchorEl={open}
        open={!!open}
        onClose={handleClose}
        sx={{
          width: "auto",
          "& .MuiMenuItem-root": { typography: "body2", borderRadius: 0.75 },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === query}
            onClick={() => handleSortBy(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  );
}

import axiosInstance from "src/utils/axios";

export class UtilService {
    static async fetchGamerTags() {
        try {
            const response = await axiosInstance.get(
                `${process.env.REACT_APP_API_BASE_URL}/gamer-tags`
            );
            if (response.status === 200) {
                return response.data
            }
        } catch (error) {
            console.error(error);
        }
    };

    static async fetchGames() {
        try {
            const response = await axiosInstance.get(
                `${process.env.REACT_APP_API_BASE_URL}/games`
            );
            if (response.status === 200) {
                return response.data
            }
        } catch (error) {
            console.error(error);
        }
    };
    static async fetchOfferedDegress() {
        try {
            const response = await axiosInstance.get(
                `${process.env.REACT_APP_API_BASE_URL}/colleges/degrees`
            );
            if (response.status === 200) {
                return response.data
            }
        } catch (error) {
            console.error(error);
        }
    }
}
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { Link, Card, Stack, Box, Typography } from "@mui/material";
import { PATH_DASHBOARD } from "src/routes/paths";
import Image from "src/components/Image";
import Iconify from "src/components/Iconify";

// ----------------------------------------------------------------------

SquadCard.propTypes = {
  squad: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function SquadCard({ squad }) {
  const linkTo = PATH_DASHBOARD.squads.view(squad._id);

  return (
    <Card
      sx={{
        pt: 3,
        pl: 3,
        pr: 3,
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
      }}
    >
      <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
        {squad.players && squad.players.length > 0 && (
          <Stack
            flexWrap="wrap"
            direction="row"
            sx={{
              color: "common.white",
              height: "50px",
              ml: 3,
              mt: 3,
            }}
          >
            {squad.players.slice(0, 5).map((player, i) => (
              <Image
                src={player.avatarUrl}
                disabledEffect
                sx={{
                  width: "45px",
                  height: "45px",
                  overflow: "visible",
                  zIndex: 5 - i,
                  "& .MuiBox-root": {
                    borderRadius: "100%",
                    ml: i > 0 && "-" + i * 6 + "px",
                  },
                }}
              />
            ))}
          </Stack>
        )}
        <Link sx={{ mt: 3, mb: 2 }} to={linkTo} color="inherit" component={RouterLink}>
          <Typography variant="h6"  persistent>
            {squad.name}
          </Typography>
        </Link>

        <Box
        sx={{
          mb: 5,
          display: "flex",
          alignItems: "center",
          gap: "3px",
          fontSize: "13px",
          color: "white",
          opacity: "0.9",
        }}
      >
        <Iconify icon="ic:baseline-people" sx={{ width: 16 }} />
        {squad.players.length}&nbsp;prospects
      </Box>
      </Stack>

      
    </Card>
  );
}

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
// import { DialogContent, Typography } from "@mui/material";

export default function TeamDeleteDialog({ open, setOpen }) {
  const handleClose = () => {
    setOpen({ open: false, func: null });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this team?
        </DialogTitle>
        {/* <DialogContent>
          <Typography sx={{ mt: 3 }}>
            We will send them an email advising that they were removed from the
            team.
          </Typography>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              open.func && open.func();
              handleClose();
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default function TextField(theme) {
    return {
        MuiTextField: {
            styleOverrides: {
                root: {
                    input: {
                        '&:-webkit-autofill': {
                          WebkitTextFillColor: 'white',
                          backgroundClip: "text"
                          
                        },
                        
                      },
                  
                },
            },
        },
    }
}
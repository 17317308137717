import PropTypes from 'prop-types';
import About from './About';
import EsportsTitles from './EsportsTitles';
import Socials from './Socials';
import DegreesOffered from './DegreesOffered';
import PlayerTeamInfo from './PlayerTeamInfo';


Info.propTypes = {
    organization: PropTypes.object,
};

export default function Info({ organization }) {
    return (
        <>
            <PlayerTeamInfo teams={organization?.totalNumberOfTeamsInOrganization} players={organization?.totalNumberOfPlayersInOrganization}/>
            <About organization={organization} />
            <EsportsTitles esportsTitles={organization?.esportsTitles} />
            <Socials socials={organization?.socials} />
            <DegreesOffered degrees={organization?.degreesOffered} />
        </>
    );
}


// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Chip, Divider, Stack, Typography } from '@mui/material';
// redux
//
import Markdown from '../../../components/Markdown';
import Scrollbar from '../../../components/Scrollbar';
import MailDetailsToolbar from './MailDetailsToolbar';
import { useMemo } from 'react';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

const MarkdownStyle = styled('div')(({ theme }) => ({
  '& > p': {
    ...theme.typography.body1,
    marginBottom: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------
export const RequestDetails = ({ request, handleAccept, handleReject, handleRevoke }) => {
  const { user } = useAuth();
  return request.type === "Invite" ?
    <InviteDetails invite={request} handleAccept={handleAccept} handleReject={handleReject} handleRevoke={handleRevoke}/> :
    <JoinDetails user={user} join={request} handleAccept={handleAccept} handleReject={handleReject} handleRevoke={handleRevoke}/>;
}

export default RequestDetails;

export function JoinDetails({ join, handleAccept, handleReject, handleRevoke, user }) {
  return (
    <RootStyle>
      <MailDetailsToolbar mail={{
        name: join?.sender?.userId?.username,
        avatar: join?.sender?.userId?.avatarUrl,
        createdAt: join?.createdAt,
        status: join.status
      }}
      />
      <Divider />
      <Box sx={{ p: { xs: 3, md: 5 } }}>
        <Scrollbar sx={{ flexGrow: 1, height: "35vh" }} >
          <Typography variant="h3" gutterBottom>
            {join.title}
          </Typography>
          <MarkdownStyle sx={{}}>
            <Markdown children={join?.inviteMessage} />
          </MarkdownStyle>

        </Scrollbar>
        {join.status === "Pending" && !(join.sender.userId._id === user._id) &&
          (
            <Stack direction={"row"} justifyContent={"flex-end"} p={2} sx={{position: "absolute", bottom: 1, right: 1}}>
              <Button variant="outlined" sx={{ mr: 2 }} onClick={() => handleReject(join._id)}>Reject</Button>
              <Button variant="contained" onClick={() => handleAccept(join._id)}>Accept </Button>
            </Stack>
          )
        }
        {join.status === "Pending" && (join.sender.userId._id === user._id) &&
          (
            <Stack direction={"row"} justifyContent={"flex-end"} p={2} sx={{position: "absolute", bottom: 1, right: 1}}>
              <Button variant="outlined" sx={{ mr: 2 }} onClick={() => handleRevoke(join._id)}>Revoke</Button>
            </Stack>
          )
        }
      </Box>
    </RootStyle>
  );
}


export function InviteDetails({ invite, handleAccept, handleReject, handleRevoke }) {
  return (
    <RootStyle>
      <MailDetailsToolbar mail={{
        name: invite?.relatedTeam?.name,
        avatar: invite?.relatedTeam?.avatarUrl,
        createdAt: invite?.createdAt,
        status: invite.status
      }}
      />
      <Divider />
      <Box sx={{ p: { xs: 3, md: 5 } }}>
        <Scrollbar sx={{ flexGrow: 1, height: "35vh" }} >
          <Typography variant="h3" sx={{ mb: 1 }} gutterBottom>
            {invite.title}
          </Typography>
          <GamePositionsList games={invite.positionsList} />
          <MarkdownStyle sx={{ mt: 2 }}>
            <Markdown children={invite?.inviteMessage} />
          </MarkdownStyle>
        </Scrollbar>

      </Box>
      {invite.status === "Pending" && !invite.owner &&
        (
          <Stack direction={"row"} justifyContent={"flex-end"} p={2} sx={{position: "absolute", bottom: 1, right: 1}}>
            <Button variant="outlined" sx={{ mr: 2 }} onClick={() => handleReject(invite._id)}>Reject</Button>
            <Button variant="contained" onClick={() => handleAccept(invite._id)}>Accept </Button>
          </Stack>
        )
      }
      {invite.status === "Pending" && invite.owner &&
        (
          <Stack direction={"row"} justifyContent={"flex-end"} p={2} sx={{position: "absolute", bottom: 1, right: 1}}>
            <Button variant="outlined" sx={{ mr: 2 }} onClick={() => handleRevoke(invite._id)}>Revoke</Button>
          </Stack>
        )
      }
    </RootStyle>
  );
}


export const GamePositionsChip = ({ game }) => {
  const label = useMemo(() => {
    if (!game.positions.length) return game.game.name;
    return `${game.game.name} - ${game.positions.join(", ")}`;
  }, [game])

  return (
    <Chip key={game._id} label={label}
      sx={{
        backgroundColor: "#4c13ac",
        color: "white",
        fontWeight: 500,
        height: "fit-content",
        py: 0.5,
        mr: 0.5
      }} />
  )
}

export const GamePositionsList = ({ games }) => {
  return <Box my={1}>
    {games.map(game => <GamePositionsChip game={game} key={game._id} />)}
  </Box>
}


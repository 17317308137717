import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Card, Stack, Typography, Container, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { fData } from "../../../../../utils/formatNumber";
import {
  FormProvider,
  RHFCheckbox,
  RHFUploadAvatar,
} from "../../../../../components/hook-form";
import "react-country-state-city/dist/react-country-state-city.css";
import { Validation } from "src/utils/validations";
import useGames from "src/hooks/useGames";
import Info from "../OrganizationAccountGeneral/Info";
import Socials from "../OrganizationAccountGeneral/Socials";
import GamesPlayed from "../OrganizationAccountGeneral/GamesPlayed";

import EsportsCard from "../OrganizationAccountGeneral/EsportsCard";
import LocationCard from "../OrganizationAccountGeneral/Location";
import Page from "src/components/Page";
import useSettings from "src/hooks/useSettings";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import RHFEditorCard from "./RHFEditorCard";
import TeamDeleteDialog from "./TeamDeleteDialog";

export default function TeamAccountForm({
  title,
  profile,
  submit,
  deleteTeam,
  organizationActive,
  navigate,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();
  const { games } = useGames();
  const defaultValues = useMemo(() => processTeam(profile), [profile]);
  const methods = useForm({
    resolver: yupResolver(Validation.TeamAccountValidationSchema),
    defaultValues,
    mode: "onBlur",
  });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState({
    open: false,
    func: null,
  });

  const {
    setValue,
    handleSubmit,
    watch,
    setError,
    control,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();
  const onSubmit = async () => {
    try {
      await submit(values);

      enqueueSnackbar(
        profile?._id
          ? "Changes have been saved."
          : "New team has been created.",
        {
          autoHideDuration: 2000,
          variant: "success",
        }
      );
      navigate();
    } catch (error) {
      console.log(error);
      setError("afterSubmit", { ...error, message: error.message }); //Show an alert box if state errors
    }
  };

  useEffect(() => {
    const errorsKeys = Object.keys(errors);
    if (errorsKeys.length && isSubmitting) {
      enqueueSnackbar(`${errors[errorsKeys[0]].message}`, {
        autoHideDuration: 2000,
        variant: "error",
      });
    }
  }, [errors, isSubmitting, enqueueSnackbar]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          "photoURL",
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const [isTeamDeleteInProgress, setIsTeamDeleteInProgress] = useState(false);
  const handleDeleteTeam = async () => {
    try {
      setIsTeamDeleteInProgress(true);
      await deleteTeam();
      window.location.replace("/dashboard/teams");
      setIsTeamDeleteInProgress(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Page title={title}>
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading={title} />
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card sx={{ py: 10, px: 3, textAlign: "center" }}>
                <RHFUploadAvatar
                  name="photoURL"
                  accept="image/*"
                  maxSize={1048576}
                  onDrop={handleDrop}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: "auto",
                        display: "block",
                        textAlign: "center",
                        color: "text.secondary",
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif, *.webp
                      <br /> max size of {fData(1048576)}
                    </Typography>
                  }
                />
              </Card>
            </Grid>

            <Info
              announcement={` 
                        Use this section to announce or highlight important
                        information about your team. Share key updates, such
                        as recruitment opportunities, upcoming events, or special
                        programs.
                    `}
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3, mt: 3 }}>
                <Socials />
              </Card>
            </Grid>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3, mt: 3 }}>
                <LocationCard
                  city={values.locationCity}
                  state={values.locationState}
                  country={values.locationCountryCode}
                />
              </Card>
              <Card sx={{ p: 3, mt: 3 }}>
                <GamesPlayed
                  games={games}
                  control={control}
                  name="gamesPlayedByTeam"
                />
              </Card>
            </Grid>

            <RHFEditorCard
              title={"Description"}
              label={"Description"}
              subtitle={`Provide a description of your team.`}
              name="description"
            />
            <RHFEditorCard
              title={"Private Notes"}
              label={"Private Notes"}
              subtitle={`Add any private notes about your team. These notes are only visible to you and can include strategies, player details, or other important information for your reference`}
              name="privateNotes"
            />
            <Grid container>
              <Grid item xs={12} md={12}>
                <EsportsCard
                  cntrol={control}
                  setValue={setValue}
                  values={values}
                />
              </Grid>
            </Grid>
            <Stack width={"100%"}>
              <Stack
                direction="column"
                alignItems="flex-start"
                justifyContent={"space-between"}
                width={"100%"}
              >
                <Stack sx={{ padding: 2, paddingBottom: 0 }}>
                  <RHFCheckbox
                    name="isPublic"
                    label={
                      <Typography variant="body2" align="left" sx={{ mt: 0 }}>
                        By checking this box, your team will be public and
                        searchable. This allows players, colleges, and
                        recruiters to discover your team.
                      </Typography>
                    }
                    sx={{}}
                  />
                </Stack>
                {organizationActive && (
                  <Stack sx={{ padding: 2, paddingBottom: 0 }}>
                    <RHFCheckbox
                      name="featuredOnProfile"
                      label={
                        <Typography
                          variant="body2"
                          align="left"
                          sx={{
                            ...(errors.featuredOnProfile?.message && {
                              color: "error.light",
                            }),
                          }}
                        >
                          Feature this team on my organization profile.
                        </Typography>
                      }
                      sx={{}}
                    />
                  </Stack>
                )}

                <Box sx={{ display: "flex", gap: 2, mt: 3 }}>
                  {profile?._id && !!deleteTeam && (
                    <LoadingButton
                      size="large"
                      type="button"
                      variant="outlined"
                      disabled={isSubmitting}
                      loading={isTeamDeleteInProgress}
                      onClick={() =>
                        setIsDeleteDialogOpen({
                          open: true,
                          func: handleDeleteTeam,
                        })
                      }
                    >
                      Delete Team
                    </LoadingButton>
                  )}
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting || isTeamDeleteInProgress}
                  >
                    Save Changes
                  </LoadingButton>
                </Box>
              </Stack>
            </Stack>
          </Grid>
        </FormProvider>

        <TeamDeleteDialog
          open={isDeleteDialogOpen}
          setOpen={setIsDeleteDialogOpen}
        />
      </Container>
    </Page>
  );
}

function processTeam(team, organization) {
  return {
    name: team?.name || "",
    announcement: team?.announcement || "",
    photoURL: team?.avatarUrl || "",
    avatar: team?.avatar || "",
    description: team?.description || "",
    locationCountryCode: team?.locationCountryCode || "",
    locationState: team?.locationState || "",
    locationCity: team?.locationCity || "",
    locationZipCode: team?.locationZipCode || "",
    socials: {
      instagram: team?.socials?.instagram || "",
      twitter: team?.socials?.twitter || "",
      facebook: team?.socials?.facebook || "",
      threads: team?.socials?.threads || "",
    },
    gamesPlayedByTeam: team?.gamesPlayedByTeam || [],
    esportsTitles: team?.esportsTitles || [],
    isPublic: team?.isPublic || false,
    featuredOnProfile: team?.featuredOnProfile || false,
    privateNotes: team?.privateNotes || "",
  };
}

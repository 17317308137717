import { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { FormProvider, RHFEditor } from "../hook-form";
import { useForm } from "react-hook-form";
import { PlayerService } from "src/services/player";
import { useSnackbar } from "notistack";

export default function JoinTeamDialog({
  open,
  handleClose,
  refetch,
  name,
  teamId
}) {

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    inviteMessage: "",
  };

  const methods = useForm({
    defaultValues,
    mode: "onBlur",
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (open) {
      reset(); // Reset form fields when dialog is opened
    }
  }, [open, reset]);

  const onSubmit = async (data) => {
    try {
      const { inviteMessage } = data;
      await PlayerService.requestToJoinTeam(teamId, {
        inviteMessage,
      });
      enqueueSnackbar("Requested to join team successfully.", {
        variant: "success",
        autoHideDuration: 4000,
      });
      handleClose();
      refetch();
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Failed to request to join team.", {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xl"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Request to Join Team</DialogTitle>
        <DialogContent>
          <Stack sx={{mt: 1}}>
            <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "500", mb: 1 }}>
                {`Team Name: ${name}`}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: "500", mb: 1 }}>
                Request Message
              </Typography>
              <RHFEditor name="inviteMessage" label="Message" />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}


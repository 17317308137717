import React from "react";
import Info from "./Info";
import Timeline from "./Timeline";
import { Profile } from "src/sections/@dashboard/user/profile";

const TeamProfile = ({ fetching, team }) => {
  return (
    <>
      {!fetching ? (
        <Profile
          leftNav={<Info team={team} />}
          main={<Timeline team={team} />}
        />
      ) : null}
    </>
  );
};

export default TeamProfile;

import { Card, Stack, Typography } from '@mui/material'
import React from 'react'
import { RHFEditor } from 'src/components/hook-form'

const TutionCard = () => {
    return (
        <Card sx={{ p: 3, mt: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: "500", mb: 2, ml: 1 }}>
                Tuition
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, ml: 1 }}>
                Provide a detailed breakdown of your tuition fees and associated
                costs. Include information on annual tuition rates, additional
                fees, payment plans, scholarships, and financial aid options
                available to students.
            </Typography>
            <Stack spacing={3} alignItems="flex-end" sx={{ my: 3 }}>
                <RHFEditor name="tuitionAmounts" label={"Tuition"} />
            </Stack>
        </Card>
    )
}

export default TutionCard
import PropTypes from "prop-types";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  Box,
  Link,
  Card,
  CardContent,
  Stack,
  Chip,
  Typography,
  AvatarGroup,
} from "@mui/material";
import { PATH_DASHBOARD } from "src/routes/paths";
import Iconify from "src/components/Iconify";
import TextMaxLine from "src/components/TextMaxLine";
import Avatar from "src/components/Avatar";

// ----------------------------------------------------------------------

TeamCard.propTypes = {
  team: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function TeamCard({ team, isPublicProfile = false }) {
  return (
    <Card sx={{ p: 1 }}>
      <Avatar
        alt={team?.name}
        src={team.avatarUrl}
        name={team?.name}
        sx={{
          zIndex: 9,
          top: 24,
          left: 20,
          width: { xs: 45, md: 55 },
          height: { xs: 45, md: 55 },
          position: "absolute",
        }}
      />

      <PostContent
        isPublicProfile={isPublicProfile}
        team={team}
        title={team.name}
        players={team.players}
        games={team.gamesPlayedByTeam}
        id={team._id}
      />
    </Card>
  );
}

// ----------------------------------------------------------------------

PostContent.propTypes = {
  team: PropTypes.object,
  title: PropTypes.string,
  players: PropTypes.array,
  games: PropTypes.array,
  id: PropTypes.string,
};

export function PostContent({
  title,
  players,
  games,
  id,
  team,
  isPublicProfile = false,
}) {
  let location = useLocation();

  console.log(location.pathname);

  function getLink() {
    if (location.pathname.includes("/dashboard/player")) {
      return PATH_DASHBOARD.profile.player.viewTeam(id);
    }
    if (location.pathname.includes("/dashboard/organization")) {
      return PATH_DASHBOARD.profile.organization.viewTeam(id);
    }
    if (location.pathname.includes("/dashboard/public/organization")) {
      return "public teams";
    }
    if (location.pathname.includes("/dashboard/public/player")) {
      return "public teams";
    } else {
      return PATH_DASHBOARD.teams.view(id); // what is this case used for???
    }
  }
  const linkTo = getLink();
  // const linkTo =
  //   location.pathname === "/dashboard/player"
  //     ? PATH_DASHBOARD.profile.player.viewTeam(id)
  //     : location.pathname === "/dashboard/organization"
  //     ? PATH_DASHBOARD.profile.organization.viewTeam(id)
  //     : PATH_DASHBOARD.teams.view(id);
  return (
    <CardContent
      sx={{
        pt: 12,
        width: 1,
        px: 2,
        height: "380px",
        position: "relative",
      }}
    >
      {(team.isPublic || location.pathname.includes("/dashboard/teams")) && (
        <Link to={linkTo} color="inherit" component={RouterLink}>
          <TextMaxLine variant="h6" line={1} persistent>
            {title || "No Title"}
          </TextMaxLine>
        </Link>
      )}

      {(!team.isPublic && !location.pathname.includes("/dashboard/teams"))&& (
        <TextMaxLine variant="h6" line={1} persistent>
          {title || "No Title"}
        </TextMaxLine>
      )}

      {!!team.linkedOrganization && team.linkedOrganization.name ? (
        <Typography
          sx={{
            color: "text.disabled",
            opacity: 0.9,
            fontSize: "14px",
            mt: "5px",
          }}
        >
          {team.linkedOrganization.name}
        </Typography>
      ) : (
        ""
      )}

      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "3px",
          fontSize: "13px",
          color: "white",
          opacity: "0.7",
          mt: "6px",
        }}
      >
        <Iconify icon="ic:baseline-people" sx={{ width: 16 }} />
        {players.length}&nbsp;players
      </Box>

      <Box
        sx={{
          mt: 1.8,
          display: "flex",
          flexWrap: "wrap",
          gap: "5px",
          fontSize: "12px",
        }}
      >
        {games.map((game) => (
          <Chip label={game.name}></Chip>
        ))}
      </Box>

      {team.isPublic === false && (
        <Chip sx={{ mt: 1 }} label={"Private"}></Chip>
      )}

      <Stack
        flexWrap="wrap"
        direction="row"
        justifyContent="flex-end"
        sx={{
          position: "absolute",
          bottom: "20px",
          right: "10px",
          color: "common.white",
          height: "30px",
        }}
      >
        <AvatarGroup max={5}>
          {players.slice(0, 5).map((player, i) => (
            <Avatar
              src={player.avatarUrl}
              name={player.userId.username}
              sx={{
                width: "30px",
                height: "30px",
                // overflow: "visible",
                // zIndex: 5 - i,
                // "& .MuiBox-root": {
                //   borderRadius: "100%",
                //   ml: i > 0 && "-" + i * 6 + "px",
                // },
              }}
            />
          ))}
        </AvatarGroup>
      </Stack>
    </CardContent>
  );
}

import orderBy from "lodash/orderBy";
import { Link as RouterLink } from "react-router-dom";
import React, { useState, useMemo } from "react";
// @mui
import {
  Grid,
  Button,
  Container,
  Stack,
  Input,
  Typography,
  CircularProgress,
} from "@mui/material";
import useSettings from "../../hooks/useSettings";
import { PATH_DASHBOARD } from "../../routes/paths";
import Page from "../../components/Page";
import Iconify from "../../components/Iconify";
import { SkeletonPostItem } from "../../components/skeleton";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import SquadCard from "src/sections/@dashboard/squads/SquadCard";
import { SquadsSortNew } from "src/sections/@dashboard/squads";
import useSquads from "src/hooks/useSquads";

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: "Name", label: "Name" },
  { value: "Last Edited", label: "Last Edited" },
];

// ----------------------------------------------------------------------

const applySort = (squads, sortBy) => {
  let parsedSquads = squads.map(squad => ({...squad, loweredCaseName: squad.name.toLowerCase(), lastEditedTimeStamp: new Date(squad.lastEdited).getTime()}))
  if (sortBy === "Name") {
    return orderBy(parsedSquads, ["loweredCaseName"]);
  }
  if (sortBy === "Last Edited") {
    return orderBy(parsedSquads, ["lastEditedTimeStamp"], ["desc"]);
  }
  return squads;
};

export default function MyProspects() {
  const { themeStretch } = useSettings();
  const { squads, isLoading } = useSquads();
  const [filters, setFilters] = useState("Name");
  const [searchVal, setSearchVal] = useState("");
  const sortedSquads = useMemo(() => {
    if (!searchVal.trim()) {
      return applySort(squads, filters)
    }
    return squads.filter((squad) => squad.name.toLowerCase().includes(searchVal.toLowerCase()));
  }, [squads, filters, searchVal]);

  const handleChangeSort = (value) => {
    if (value) {
      setFilters(value);
    }
  };

  return (
    <Page title="My Prospects">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="My Prospects"
          links={[]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.squads.new}
              startIcon={<Iconify icon={"eva:plus-fill"} />}
            >
              Create a New Squad
            </Button>
          }
        />

        <Stack
          mb={5}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Input
            value={searchVal}
            disableUnderline
            onChange={(event) => setSearchVal(event.target.value)}
            placeholder="Search squads..."
            sx={{
              border: "1px solid #ddd3",
              borderRadius: "8px",
              py: 1,
              px: 2,
              fontSize: "14px",
            }}
          />

          <SquadsSortNew
            query={filters}
            options={SORT_OPTIONS}
            onSort={handleChangeSort}
          />
        </Stack>

        <Grid container spacing={3} sx={{ mt: 2 }}>
          {sortedSquads.length
            ? sortedSquads.map((squad, index) =>
              squad ? (
                <Grid key={squad._id} item xs={12} sm={6} md={4}>
                  <SquadCard squad={squad} />
                </Grid>
              ) : (
                <SkeletonPostItem key={index} />
              )
            )
            : ""}
        </Grid>
        

        {!sortedSquads.length && !isLoading && (
          <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
            No squads found.
          </Typography>
        )}

        {isLoading && (
          <Stack alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}
      </Container>
    </Page>
  );
}

import { Card, Typography } from '@mui/material'
import React from 'react'
import Markdown from 'src/components/Markdown'

const CollegeProfileStatement = ({ profile }) => {
    return (
        <Card sx={{ p: 3 }}>
            <Typography variant='h5' sx={{ mb: 1 }}>Statement</Typography>
            <Markdown children={profile?.collegeDetails?.statement || ""} />
        </Card>
    )
}

export default CollegeProfileStatement
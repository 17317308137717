import { Card, CardHeader, Chip, Stack } from '@mui/material'
import React from 'react'

const DegreesOffered = ({ degrees = [] }) => {
    return (
        <Card>
            <CardHeader title="Degrees Offered" />
            <Stack spacing={2} sx={{ p: 2 }}>
                <div>
                    {degrees.length ? degrees.map(degree => {
                        return <Chip label={degree} sx={{ mr: 1, mb: 1 }} ></Chip>
                    }) : null}
                </div>
            </Stack>
        </Card>
    )
}

export default DegreesOffered

// @mui
import { Box, Input, InputAdornment } from '@mui/material';
// redux
// hooks
// config
// components
import Iconify from '../../../components/Iconify';
//

// ---------

export const SearchBar = ({search, setSearch, placeholder}) => {
    return(
    <Box sx={{ p: 3 }}>
      <Input
        value={search}
        disableUnderline
        fullWidth
        onChange={(event) => setSearch(event.target.value)}
        placeholder={placeholder}
        startAdornment={
          <InputAdornment position="start">
            <Iconify
              icon={"eva:search-fill"}
              sx={{ width: 20, height: 20, color: "text.disabled" }}
            />
          </InputAdornment>
        }
        sx={{
          border: "1px solid #ddd3",
          borderRadius: "8px",
          py: 1,
          px: 2,
          fontSize: "14px",
        }}
      />
    </Box>)
  }
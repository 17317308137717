import { Box, Typography, InputAdornment } from "@mui/material";
import { RHFTextField } from "src/components/hook-form";
import Iconify from "src/components/Iconify";
import { styled } from "@mui/material/styles";

const Socials = () => {
  const IconStyle = styled(Iconify)(({ theme }) => ({
    width: 20,
    height: 20,
    marginTop: 1,
    flexShrink: 0,
    marginRight: theme.spacing(2),
  }));
  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: "500", mb: 2, ml: 1 }}>
        Socials
      </Typography>

      <Box
        sx={{
          display: "grid",
          rowGap: 3,
          columnGap: 2,
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
          },
        }}
      >
        <RHFTextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconStyle icon={"prime:twitter"} color="#1C9CEA" />
              </InputAdornment>
            ),
          }}
          name="socials.twitter"
          label="Twitter"
        />
        <RHFTextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconStyle
                  icon={"ant-design:instagram-filled"}
                  color="#D7336D"
                />
              </InputAdornment>
            ),
          }}
          name="socials.instagram"
          label="Instagram"
        />
        <RHFTextField
          name="socials.threads"
          label="Threads"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconStyle icon={"mingcute:threads-fill"} color="#ffffff" />
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="socials.facebook"
          label="Facebook"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconStyle icon={"eva:facebook-fill"} color="#1877F2" />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>
  );
};

export default Socials;

// @mui
import { styled } from "@mui/material/styles";
import { Tab, Box, Card, Tabs, Container } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../../routes/paths";
// hooks
import useAuth from "../../../../hooks/useAuth";
import useTabs from "../../../../hooks/useTabs";
import useSettings from "../../../../hooks/useSettings";
// _mock_
// import { _userAbout } from '../../_mock';
// components
import Page from "../../../../components/Page";
import Iconify from "../../../../components/Iconify";
import HeaderBreadcrumbs from "../../../../components/HeaderBreadcrumbs";
// sections
import { useMemo } from "react";
import MyOrganizationProfile from "src/sections/@dashboard/user/profile/MyOrganizationProfile";
import OrganizationCover from "src/sections/@dashboard/user/profile/MyOrganizationProfile/OrganizationCover";
import Loader from "../../Loader";
import usePublicOrganization from "src/hooks/usePublicOrganization";
import { useParams } from "react-router";
import FeaturedPlayers from "./featuredPlayer";
import FeaturedTeams from "./featuredTeams";

// ----------------------------------------------------------------------

const TabsWrapperStyle = styled("div")(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: "100%",
  display: "flex",
  position: "absolute",
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up("sm")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.up("md")]: {
    justifyContent: "flex-end",
    paddingRight: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

export default function PublcOrganizationProfile() {
  const { themeStretch } = useSettings();
  const { user } = useAuth();
  const {id } = useParams()
  const { profile, fetching } = usePublicOrganization(id);

  const { currentTab, onChangeTab } = useTabs("details");

  const PROFILE_TABS = useMemo(() => {
    return [
      {
        value: "details",
        icon: <Iconify icon={"jam:gamepad"} width={20} height={20} />,
        component: (
          <MyOrganizationProfile fetching={fetching} organization={profile} />
        ),
      },
      {
        value: "Featured Teams",
        icon: (
          <Iconify
            icon={"fluent:people-team-24-regular"}
            width={20}
            height={20}
          />
        ),
        component: !fetching ? (
          <FeaturedTeams featuredTeams={profile?.featuredTeams}/>
        ) : null,
      },
      {
        value: "Featured Players",
        icon: (
          <Iconify
            icon={"fluent:people-team-24-filled"}
            width={20}
            height={20}
          />
        ),
        component: !fetching ? (
          <FeaturedPlayers featuredPlayers={profile?.featuredPlayers} />
        ) : null,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, fetching]);

  return (
    <Page title="Organization Profile">
      <Loader loading={fetching}>
        <Container maxWidth={themeStretch ? false : "lg"}>
          <HeaderBreadcrumbs
            heading="Organization Profile"
            links={[
              { name: "Dashboard", href: PATH_DASHBOARD.root },
              { name: "User", href: PATH_DASHBOARD.user.root },
              { name: user?.displayName || "" },
            ]}
          />
          <Card
            sx={{
              mb: 3,
              height: 280,
              position: "relative",
            }}
          >
            <OrganizationCover
              profile="organizationProfileImage"
              title={profile?.name}
              subtitle="College"
              isPublicProfile
              email={profile?.email}
            />
            <TabsWrapperStyle>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={currentTab}
                onChange={onChangeTab}
              >
                {PROFILE_TABS.map((tab) => (
                  <Tab
                    disableRipple
                    key={tab.value}
                    value={tab.value}
                    icon={tab.icon}
                    label={tab.value}
                  />
                ))}
              </Tabs>
            </TabsWrapperStyle>
          </Card>

          {PROFILE_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Container>
      </Loader>
    </Page>
  );
}


// @mui
// components
import { Profile } from "../../user/profile";
import Info from "./Info";
import PlayerDetails from "./Details";

const ManagedPlayerView = ({ player, playerSquads, playerTeams, refetch }) => {
    return (
        <>
            <Profile
                leftNav={<Info player={player} teams={playerTeams} squads={playerSquads} refetch={refetch} />}
                main={<PlayerDetails squads={playerSquads} teams={playerTeams} />}
            />
        </>
    );
};

export default ManagedPlayerView;











function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  // pricing: '/pricing',
  // payment: '/payment',
  // about: '/about-us',
  // contact: '/contact-us',
  // faqs: '/faqs',
  page403: "/403",
  page404: "/404",
  page500: "/500",
  // components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  app: path(ROOTS_DASHBOARD, "/app"),
  uploads: {
    root: path(ROOTS_DASHBOARD, "/uploads"),
    new: path(ROOTS_DASHBOARD, "/uploads/new"),
  },
  search: path(ROOTS_DASHBOARD, "/search"),
  players: path(ROOTS_DASHBOARD, "/players"),
  public: {
    player: (id) => path(ROOTS_DASHBOARD, `/public/player/${id}`),
  },
  teams: {
    root: path(ROOTS_DASHBOARD, "/teams"),
    view: (id) => path(ROOTS_DASHBOARD, `/teams/${id}`),
    new: path(ROOTS_DASHBOARD, "/teams/new"),
  },
  squads: {
    root: path(ROOTS_DASHBOARD, "/squads"),
    view: (id) => path(ROOTS_DASHBOARD, `/squads/${id}`),
    new: path(ROOTS_DASHBOARD, "/squads/new"),
  },
  profile: {
    player: {
      root: path(ROOTS_DASHBOARD, "/player"),
      viewTeam: (id) => path(ROOTS_DASHBOARD, `/player/team/${id}`),
    },
    organization: {
      root: path(ROOTS_DASHBOARD, "/organization"),
      viewTeam: (id) => path(ROOTS_DASHBOARD, `/organization/team/${id}`),
    },
  },

  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    // ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    // analytics: path(ROOTS_DASHBOARD, '/analytics'),
    // banking: path(ROOTS_DASHBOARD, '/banking'),
    // booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  // mail: {
  //   root: path(ROOTS_DASHBOARD, '/mail'),
  //   all: path(ROOTS_DASHBOARD, '/mail/all'),
  // },
  // chat: {
  //   root: path(ROOTS_DASHBOARD, '/chat'),
  //   new: path(ROOTS_DASHBOARD, '/chat/new'),
  //   view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  // },
  // calendar: path(ROOTS_DASHBOARD, '/calendar'),
  // kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    // new: path(ROOTS_DASHBOARD, '/user/new'),
    // list: path(ROOTS_DASHBOARD, '/user/list'),
    // cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    viewProfileUpload: (userId, uploadId) =>
      path(ROOTS_DASHBOARD, `/user/${userId}/uploads/${uploadId}`),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    // demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  // eCommerce: {
  //   root: path(ROOTS_DASHBOARD, '/e-commerce'),
  //   shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
  //   list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
  //   checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
  //   new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
  //   view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
  //   edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
  //   demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
  //   demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  // },
  // invoice: {
  //   root: path(ROOTS_DASHBOARD, "/invoice"),
  //   list: path(ROOTS_DASHBOARD, "/invoice/list"),
  //   new: path(ROOTS_DASHBOARD, "/invoice/new"),
  //   view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
  //   edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
  //   demoEdit: path(
  //     ROOTS_DASHBOARD,
  //     "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit"
  //   ),
  //   demoView: path(
  //     ROOTS_DASHBOARD,
  //     "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5"
  //   ),
  // },
  // blog: {
  //   root: path(ROOTS_DASHBOARD, "/blog"),
  //   posts: path(ROOTS_DASHBOARD, "/blog/posts"),
  //   new: path(ROOTS_DASHBOARD, "/blog/new"),
  //   view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
  //   demoView: path(
  //     ROOTS_DASHBOARD,
  //     "/blog/post/apply-these-7-secret-techniques-to-improve-event"
  //   ),
  // },
};

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";

import React, { useEffect, useState } from "react";
// @mui
import {
  Container,
  Stack,
  CircularProgress,
  TablePagination,
  Box,
  TableBody,
  Table,
  Divider,
  TableContainer,
  Card,
} from "@mui/material";
import useSettings from "../../hooks/useSettings";
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import usePlayersIManage from "src/hooks/usePlayersIManage";
import useTable, { emptyRows, getComparator } from "src/hooks/useTable";
import useTabs from "src/hooks/useTabs";
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from "src/components/table";
import {
  PlayersTableRow,
  PlayersTableToolbar,
} from "src/sections/@dashboard/player/manage/index";
import Scrollbar from "src/components/Scrollbar";
import useOrganizationProfile from "src/hooks/useOrganizationProfile";

const TABLE_HEAD = [
  { id: "username", label: "Username", align: "left" },
  { id: "email", label: "Email", align: "left" },
  { id: "teams", label: "Teams", align: "center" },
  { id: "games", label: "Games", align: "center" },
];

export default function PlayersIManage() {
  const { themeStretch } = useSettings();

  const { players, isLoading } = usePlayersIManage();
  const { profile: orgProfile , refetch} = useOrganizationProfile();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    selected,
    onSelectAllRows,
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  useEffect(() => {
    setTableData(
      players.map((player) => {
        player.username = player.userId.username;
        player.email = player.userId.email;
        return player;
      })
    );
  }, [players]);

  const [tableData, setTableData] = useState(players);

  const [filterName, setFilterName] = useState("");

  const { currentTab: filterStatus } = useTabs("all");

  const handleSearchFilter = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterStatus,
  });

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterStatus);

  const denseHeight = dense ? 56 : 76;

  return (
    <Page title="Players I Manage">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading="Players I Manage" links={[]} />

        <Card>
          <Divider />

          <PlayersTableToolbar
            filterName={filterName}
            onFilterName={handleSearchFilter}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: "relative" }}>

              <Table size={dense ? "small" : "medium"}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row._id)
                    )
                  }
                />

                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <PlayersTableRow
                        key={row._id}
                        row={row}
                        orgProfile={orgProfile}
                        selected={selected.includes(row._id)}
                        refetch={refetch}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={!isLoading && isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>

            {isLoading && (
              <Stack alignItems="center" justifyContent="center">
                <CircularProgress sx={{ mb: 5, mt: 5 }} />
              </Stack>
            )}
          </Scrollbar>

          <Box sx={{ position: "relative" }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

function applySortFilter({ tableData, comparator, filterName, filterStatus }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  // user name, email, team name, and games/positions
  if (filterName) {
    tableData = tableData.filter(
      (item) =>
        item.userId.username.toLowerCase().indexOf(filterName.toLowerCase()) !==
          -1 ||
        item.userId.email.toLowerCase().indexOf(filterName.toLowerCase()) !==
          -1 ||
        item.games
          .map((game) => game.name)
          .join(" ")
          .toLowerCase()
          .indexOf(filterName.toLowerCase()) !== -1 ||
        item.teams
          .map((game) => game.name)
          .join(" ")
          .toLowerCase()
          .indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== "all") {
    tableData = tableData.filter((item) => item.status === filterStatus);
  }

  return tableData;
}

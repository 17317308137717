import React from "react";
import Profile from "../Profile";
import Info from "./Info";
import Timeline from "./Timeline";

const MyOrganizationProfile = ({ fetching, organization }) => {
  return (
    <>
      {!fetching ? (
        <Profile
          leftNav={<Info organization={organization} />}
          main={<Timeline organization={organization} />}
        />
      ) : null}
    </>
  );
};

export default MyOrganizationProfile;

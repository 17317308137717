import axios from "axios";

const { default: axiosInstance } = require("src/utils/axios");

export class OrganisationService {
    static getHeaders() {
        return {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: localStorage.getItem("accessToken"),
            },
        };
    }


    static async patchOrganizationProfileById(userId, organizationId, values) {
        try {
            const response = await axiosInstance.patch(
                `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/organizations/${organizationId}`, values,
                {
                    headers: OrganisationService.getHeaders()
                }
            );
            if (response.status === 200) {
                return response.data
            }

        } catch (error) {
            console.error(error)
        }
        return null
    }

    static async getPublicOrganizationProfileById(id) {
        try {
            const response = await axiosInstance.get(
                `${process.env.REACT_APP_API_BASE_URL}/organizations/${id}/public`,
                OrganisationService.getHeaders()
            );
            if (response.status === 200) {
                return response.data
            }

        } catch (error) {
            console.error(error)
        }
        return null
    }
    static async patchOrganizationPublicSettingsById(userId, organizationId, isPublic) {
        try {
            const response = await axiosInstance.patch(
                `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/organizations/${organizationId}/is-public`, { isPublic },
                {
                    headers: OrganisationService.getHeaders()
                }
            );
            if (response.status === 200) {
                return response.data
            }

        } catch (error) {
            console.error(error)
        }
        return null
    }

    static async getOrganizationProfileByUserId(user) {
        try {
            const response = await axiosInstance.get(
                `${process.env.REACT_APP_API_BASE_URL}/users/${user._id}/organizations`,
                OrganisationService.getHeaders()
            );
            if (response.status === 200) {
                return response.data
            }

        } catch (error) {
            console.error(error)
        }
        return null
    }
    static async uploadFileToOrganization(userId, organizationId, file) {
        try {
            let formData = new FormData();
            formData.append("files", file);
            const response = await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/organizations/${organizationId}/uploadFile`,
                data: formData,
                headers: {
                    Authorization: localStorage.getItem("accessToken"),
                    "Content-Type": "multipart/form-data",
                },
            });
            if (response.status === 200) {
                return response.data
            }
        } catch (error) {
            console.error(error)
        }
        return null
    }
    static async deleteVideoFile(userId, organizationId, bucketKey) {
        try {
            await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/organizations/${organizationId}/deleteVideoFile`,
                { bucketKey: bucketKey },
                OrganisationService.getHeaders()
            );
        } catch (error) {
        }
        return null
    }
}
import { styled } from "@mui/material/styles";

import { Stack, Typography } from "@mui/material";
import Label from "src/components/Label";

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.up(1368)]: {
    padding: theme.spacing(4, 3),
  },
}));

export default function UploadDetailsSummary({ upload, videoUrl, ...other }) {

  return (
    <RootStyle {...other}>
      <>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" justifyContent="space-between" >
            {upload.game !== null && (
              <Label
                variant={"filled"}
                color="warning"
                sx={{ mr: 1, textTransform: "capitalize" }}
              >
                {upload.game}
              </Label>
            )}

            {upload.gameUserName && (
              <Label
                variant={"filled"}
                color="info"
                sx={{  }}
              >
                {"@" + upload?.gameUserName}
              </Label>
            )}
          </Stack>
        </Stack>
        {upload.customName !== null && (
          <Stack justifyContent="space-between" sx={{ my: 1 }}>
            <Typography
              variant="h5"
              sx={{ mt: 0.5 }}
              dangerouslySetInnerHTML={{ __html: upload.customName }}
            ></Typography>
          </Stack>
        )}
        {upload.description !== null && (
          <Stack justifyContent="space-between" sx={{ my: 1 }}>
            <Typography
              variant="body1"
              sx={{ mt: 0.5 }}
              dangerouslySetInnerHTML={{ __html: upload.description }}
            ></Typography>
          </Stack>
        )}
      </>
    </RootStyle>
  );
}

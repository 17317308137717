import { Card, Stack, Typography } from '@mui/material'
import React from 'react'
import { RHFEditor } from 'src/components/hook-form'

const AcademicCard = () => {
    return (
        <Card sx={{ p: 3, mt: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: "500", mb: 2, ml: 1 }}>
                Academic / Admission Requirements
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, ml: 1 }}>
                Please provide detailed information about your academic or
                admission requirements. Include GPA criteria, standardized test
                scores, application deadlines, and any other relevant
                information that prospective students need to know to apply.
            </Typography>
            <Stack spacing={3} alignItems="flex-end" sx={{ my: 3 }}>
                <RHFEditor
                    name="academicAdmissionRequirements"
                    label={"Academic / Admission Requirements"}
                />
            </Stack>
        </Card>
    )
}

export default AcademicCard
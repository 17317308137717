import  { useEffect, useState } from 'react'
import { UtilService } from 'src/services/util'

const useGames = () => {
    const [games, setGames] = useState([])
    useEffect(() => {
        (async () => {
            const tags  = await UtilService.fetchGames()
            if(tags) {
                setGames(tags)
            }
        })()
    }, [])
    return {
        games
    }
}

export default useGames
import {  useEffect, useMemo } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Box, DialogContent, Stack, TextField } from "@mui/material";
import { FormProvider, } from "../hook-form";
import { Controller, useForm } from "react-hook-form";
import { PlayerService } from "src/services/player";
import { useSnackbar } from "notistack";
import useAuth from "src/hooks/useAuth";
import { yupResolver } from "@hookform/resolvers/yup";
import { Validation } from "src/utils/validations";

export default function AddToSquadDialog({ open, handleClose, squadsList, player, refetch }) {

    const { enqueueSnackbar } = useSnackbar()
    const { user } = useAuth()


    const methods = useForm({
        defaultValues: { selectedSquads: [] },
        mode: "onBlur",
        resolver: yupResolver(Validation.AddtoSquadValidation),
    });

    const {
        handleSubmit,
        control,
        reset,
        formState: {  errors },
    } = methods;

    useEffect(() => {
        if (open) {
            reset(); // Reset form fields when dialog is opened
        }
    }, [open, reset]);

    const onSubmit = async ({ selectedSquads }) => {
        try {

            await Promise.all(selectedSquads.map(async squad => {
                await PlayerService.addPlayerToSquad(user._id, squad.id, {
                    players: [player._id]
                })
            }))
            refetch();
            enqueueSnackbar("Player added to squad successfully.", {
                autoHideDuration: 4000,
                variant: "success",
            });
            handleClose();
            reset()

        }
        catch (err) {
            enqueueSnackbar("Failed to add player to squad ", {
                autoHideDuration: 4000,
                variant: "success",
            });
        }

    };


    const squads = useMemo(() => {
        return squadsList.map(({ _id, name }) => ({ label: name, id: _id }))
    }, [squadsList])


    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Add Player to Squads
                </DialogTitle>
                <DialogContent>
                    <Stack width={500}>
                        <Box sx={{ mt: 2 }}  >
                            <Controller
                                name={`selectedSquads`}
                                control={control}
                                render={({ field: selectedSquadsField }) => (
                                    <Autocomplete
                                        multiple
                                        limitTags={2}
                                        fullWidth={true}
                                        {...selectedSquadsField}
                                        options={squads}
                                        getOptionKey={option => option.id}
                                        getOptionLabel={(option) => option.label}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={(_, value) => {
                                            const uniqueValues = Array.from(new Map(value.map(item => [item.id, item])).values());
                                            selectedSquadsField.onChange(uniqueValues);
                                        }}
                                        defaultValue={[]}
                                        renderInput={(params) => (
                                            <TextField
                                                error={!!errors?.selectedSquads}
                                                helperText={errors?.selectedSquads?.message || ""}
                                                {...params}
                                                label="Squads"
                                                name={"selectedSquads"}
                                                fullWidth
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Box>

                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        variant="contained"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
}
// @mui
import { styled } from "@mui/material/styles";
import { Tab, Box, Card, Tabs, Container } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
import useTabs from "../../hooks/useTabs";
import useSettings from "../../hooks/useSettings";
// _mock_
// components
import Page from "../../components/Page";
import Iconify from "../../components/Iconify";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
// sections
import { Profile, ProfileCover } from "../../sections/@dashboard/user/profile";
import PlayerAccount from "./PlayerAccount";
import { useMemo } from "react";
import PlayerProfileInfo from "src/sections/@dashboard/user/profile/MyPlayerProfile/PlayerProfileInfo";
import PlayerProfileBio from "src/sections/@dashboard/user/profile/MyPlayerProfile/PlayerProfileBio";
import CollegeProfileInfo from "src/sections/@dashboard/user/profile/MyCollegeProfile/CollegeProfileInfo";
import CollegeProfileStatement from "src/sections/@dashboard/user/profile/MyCollegeProfile/CollegeProfileStatement";
import Gallery from "src/sections/@dashboard/user/profile/MyPlayerProfile/Gallery";
import PlayerTeams from "src/sections/@dashboard/user/profile/MyPlayerProfile/PlayerTeams";
import Loader from "./Loader";
import JoinTeamRequests from "src/sections/@dashboard/user/profile/MyPlayerProfile/JoinTeamRequests";
import usePlayerProfile from "src/hooks/usePlayerProfile";

// ----------------------------------------------------------------------

const TabsWrapperStyle = styled("div")(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: "100%",
  display: "flex",
  position: "absolute",
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up("sm")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.up("md")]: {
    justifyContent: "flex-end",
    paddingRight: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

export default function PlayerProfile() {
  const { themeStretch } = useSettings();
  const { user } = useAuth();
  const { currentTab, onChangeTab } = useTabs("My Player Profile");
  const { profile, fetching, refetch, videos, invites, handleAccept, handleReject, handleRevoke } = usePlayerProfile()

  const PROFILE_TABS = useMemo(() => {
    const tabs = [
      {
        value: "My Player Profile",
        icon: <Iconify icon={"jam:gamepad"} width={20} height={20} />,
        component: fetching ? (
          <></>
        ) : (
          <Profile
            leftNav={<PlayerProfileInfo profile={profile} />}
            main={<PlayerProfileBio profile={profile} videos={videos} />}
          />
        ),
      },
      {
        value: "Video Gallery",
        icon: <Iconify icon={"mingcute:video-fill"} width={20} height={20} />,
        component: fetching ? <></> : <Gallery profile={profile} />,
      },
      {
        value: "Teams I'm On",
        icon: (
          <Iconify
            icon={"fluent:people-team-24-regular"}
            width={20}
            height={20}
          />
        ),
        component: <PlayerTeams />,
      },
      {
        value: "Invites & Join Requests",
        icon: (
          <Iconify
            icon={"ic:round-alarm-add"}
            width={20}
            height={20}
          />
        ),
        component: <Loader loading={fetching}> <JoinTeamRequests requests={invites} handleAccept={handleAccept} handleReject={handleReject} handleRevoke={handleRevoke} label={"Invites & Join Requests"} searchLabel={"Search invites and join requests..."}/></Loader>,
      },
      {
        value: "Edit",
        icon: <Iconify icon={"flowbite:edit-solid"} width={20} height={20} />,
        component: (
          <PlayerAccount
            profile={profile}
            refetch={refetch}
            fetching={fetching}
          />
        ),
      },
    ];

    if (user.goals && user.goals?.isPlayerLookingForCollegeTeam) {
      tabs.splice(1, 0, {
        value: "My College Profile",
        icon: <Iconify icon={"mdi:college-outline"} width={20} height={20} />,
        component: (
          <Profile
            leftNav={<CollegeProfileInfo profile={profile} />}
            main={<CollegeProfileStatement profile={profile} />}
          />
        ),
      });
    }

    return tabs;
    // eslint-disable-next-line
  }, [fetching, profile, videos, user.goals, invites]);


  return (
    <Page title="Player Profile">
      <Loader loading={fetching} >
        <Container maxWidth={themeStretch ? false : "lg"}>
          <HeaderBreadcrumbs
            heading="Player Profile"
            links={[
              { name: "Dashboard", href: PATH_DASHBOARD.root },
              { name: "User", href: PATH_DASHBOARD.user.root },
              { name: user?.displayName || "" },
            ]}
          />
          <Card
            sx={{
              mb: 3,
              height: 280,
              position: "relative",
            }}
          >
            <ProfileCover profile={user} />

            <TabsWrapperStyle>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={currentTab}
                onChange={onChangeTab}
                sx={{maxWidth: "88%"}}
              >
                {PROFILE_TABS.map((tab) => (
                  <Tab
                    disableRipple
                    key={tab.value}
                    value={tab.value}
                    icon={tab.icon}
                    label={tab.value}
                  />
                ))}
              </Tabs>
            </TabsWrapperStyle>
          </Card>

          {PROFILE_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Container>
      </Loader>
    </Page>
  );
}

import { Link as RouterLink, useLocation } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {  Box, Button, Typography } from "@mui/material";
// utils
import cssStyles from "src/utils/cssStyles";
// components
import Image from "src/components/Image";

import { PATH_DASHBOARD } from "src/routes/paths";
import axios from "src/utils/axios";
import useAuth from "src/hooks/useAuth";
import LeaveDialog from "./LeaveDialog";
import { useState } from "react";
import { useSnackbar } from "notistack";
import Avatar from "src/components/Avatar";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  "&:before": {
    ...cssStyles().bgBlur({ blur: 2, color: theme.palette.primary.darker }),
    top: 0,
    zIndex: 9,
    content: "''",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
}));

const InfoStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: "absolute",
  marginTop: theme.spacing(5),
  [theme.breakpoints.up("md")]: {
    right: "auto",
    display: "flex",
    alignItems: "center",
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

export default function TeamCover({
  title = "",
  subtitle = "",
  profile = "",
  cover = "",
  id = "",
}) {
  let { pathname } = useLocation();
  let { user } = useAuth();

  const [open, setOpen] = useState({ open: false, func: null });

  const { enqueueSnackbar } = useSnackbar();

  const handleLeaveTeam = () => {
    if (!pathname.startsWith("/dashboard/player")) return;
    setOpen({ open: true, func: leaveTeam });
  };

  const leaveTeam = async () => {
    try {
      await axios.get(`/teams/${id}/leave/${user._id}`);
      setOpen({ open: false, func: null });
      enqueueSnackbar("Successfully left team.", {
        autoHideDuration: 4000,
        variant: "success",
      });
      window.location.replace("/dashboard/player");
    } catch (e) {
      enqueueSnackbar("Error leaving team. Please try again.", {
        autoHideDuration: 4000,
        variant: "error",
      });
    }
  };

  return (
    <RootStyle>
      <InfoStyle
        sx={{
          width: { md: "calc(100% - 50px)" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyItems: "start",
        }}
      >
        <Avatar
          sx={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "common.white",
            width: { xs: 80, md: 128 },
            height: { xs: 80, md: 128 },
          }}
          src={profile}
          name={title}
        />

        <Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            color: "common.white",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          {title && <Typography variant="h4">{title} </Typography>}
          {subtitle && (
            <Typography sx={{ opacity: 0.72 }}>{subtitle}</Typography>
          )}
        </Box>

        {pathname.startsWith("/dashboard/player") ? (
          <Button
            sx={{ ml: { md: "auto" }, mt: { xs: 2, md: 0 } }}
            variant="contained"
            onClick={handleLeaveTeam}
          >
            Leave This Team
          </Button>
        ) : (
          <Button
            sx={{ ml: { md: "auto" }, mt: { xs: 2, md: 0 } }}
            variant="contained"
            component={RouterLink}
            to={PATH_DASHBOARD.search}
          >
            Invite Players
          </Button>
        )}
      </InfoStyle>
      <Image
        alt="profile cover"
        src={cover}
        sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
      />

      <LeaveDialog name={title} open={open} setOpen={setOpen} />
    </RootStyle>
  );
}

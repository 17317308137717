import React, { useMemo } from 'react'
import Games from './Games'
import { Alert, Card, CardHeader, Stack } from '@mui/material'
import Markdown from 'src/components/Markdown'

const Timeline = ({ organization }) => {


    const VideoComponent = useMemo(() => {
        if (organization?.video) {

            const videoID = extractYouTubeVideoID(organization.video);
            return !organization.video.includes('https://www.youtube.com/') ? <video width="100%" controls controlsList="nodownload">
                <source src={organization?.videoUrl || organization?.video} type="video/mp4" />
            </video> : <iframe
                width="100%"
                height="500"
                src={`https://www.youtube.com/embed/${videoID}`}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; 
                        autoplay; 
                        clipboard-write; 
                        encrypted-media; 
                        gyroscope; 
                        picture-in-picture; 
                        web-share" allowfullscreen></iframe>
        }
        return null
    }, [organization])
    return (
        <>
            <Games games={organization?.gamesPlayedByTeam} />
            {organization?.announcement && <Alert severity="info">
                {organization.announcement}
            </Alert>}

            {organization?.video && <Card>
                <CardHeader title="Pre-Roll Video" />
                <Stack sx={{ p: 2 }}>
                    {VideoComponent}
                </Stack>
            </Card>}
            {organization?.schoolDescription && organization?.schoolDescription !== '<p><br></p>' && <Card>
                <CardHeader title="Description" />
                <Stack sx={{ p: 3, pt: 1 }}>
                    <Markdown >
                        {organization?.schoolDescription || ""}
                    </Markdown>
                </Stack>
            </Card>}

            {organization?.academicAdmissionRequirements && organization?.academicAdmissionRequirements !== '<p><br></p>' && <Card>
                <CardHeader title="Academic / Admission Requirements" />
                <Stack sx={{ p: 3, pt: 1 }}>
                    <Markdown >
                        {organization?.academicAdmissionRequirements || ""}
                    </Markdown>
                </Stack>
            </Card>}

            {organization?.tuitionAmounts && organization?.tuitionAmounts !== '<p><br></p>' && <Card>
                <CardHeader title="Tuition" />
                <Stack sx={{ p: 3, pt: 1 }}>
                    <Markdown >
                        {organization?.tuitionAmounts || ""}
                    </Markdown>
                </Stack>
            </Card>}

            {organization?.scholarshipsOffered && organization?.scholarshipsOfferedText && organization?.scholarshipsOfferedText !== '<p><br></p>' && <Card>
                <CardHeader title="Scholarship Details" />
                <Stack sx={{ p: 3, pt: 1 }}>
                    <Markdown >
                        {organization?.scholarshipsOfferedText || ""}
                    </Markdown>
                </Stack>
            </Card>}
        </>
    )
}

export default Timeline

function extractYouTubeVideoID(url) {
    const regex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
}
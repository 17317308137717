import { useEffect, useState } from 'react'
import { OrganisationService } from 'src/services/organisation';
import useAuth from './useAuth';

const useOrganizationProfile = () => {
    const { user } = useAuth()
    const [profile, setProfile] = useState();
    const [fetching, setIsFetching] = useState(true);
    useEffect(() => {
        
        fetchProfile()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?._id])

    const updateProfileSettings = async (values) => {
        await OrganisationService.patchOrganizationProfileById(user._id, profile._id, {
           ...values,
        })
    }

    const updatePublicSettings = async (isPublic) => {
        await OrganisationService.patchOrganizationPublicSettingsById(user._id, profile._id, isPublic)
    }

    const fetchProfile = async () => {
        try {

            const profile = await OrganisationService.getOrganizationProfileByUserId(user);
            if (profile) {
                if (profile.avatarUrl) {
                    localStorage.setItem("organizationProfileImage", profile.avatarUrl)
                }
                setProfile(profile)
            }
        } catch (error) {
        }
        setIsFetching(false)
    }

    return {
        fetching,
        profile,
        updateProfileSettings,
        updatePublicSettings,
        refetch: fetchProfile
    }
}

export default useOrganizationProfile
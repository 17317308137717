import { useEffect, useMemo, useState } from 'react'
import useAuth from './useAuth';
import axios from 'src/utils/axios';
import { PlayerService } from 'src/services/player';
import { useSnackbar } from 'notistack';

const usePlayerProfile = () => {
    const { user } = useAuth()
    const [profile, setProfile] = useState();
    const [fetching, setIsFetching] = useState(true);
    const [videos, setVideos] = useState([]);
    const [inviteRequests, setInviteRequests] = useState([]);
    const [joinRequests, setJoinRequests] = useState([])
    const { enqueueSnackbar } = useSnackbar()
    useEffect(() => {
        fetchProfile()
        // eslint-disable-next-line
    }, [user?._id])

    const invites = useMemo(() => {
        return [
            ...inviteRequests.map(invite => ({ ...invite, type: "Invite" , title: `Invitation from Team ${invite.relatedTeam?.name}`,})),
            ...joinRequests.map(join => ({ ...join, type: "Join Request",  title: `Request to Join Team ${join?.requestedTeam?.name}`,  owner: true})),
        ]
    }, [inviteRequests, joinRequests])


    const fetchJoinRequests = async (playerId) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/players/${playerId || profile._id}/join-requests`
            );
            setJoinRequests(response.data);
        } catch (error) {

        }
    }


    const fetchProfile = async () => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: localStorage.getItem("accessToken"),
                },
            };
            const profileResponse = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/users/${user._id}/players`,
                config
            );

            const videosReponse = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/users/${user._id}/latest-featured-uploads`,
                config
            );

            let profile = profileResponse.data
            localStorage.setItem("playerProfileImage", profile.avatarUrl);
            setProfile(profile)
            setVideos(videosReponse.data);
            fetchInviteRequests(profile._id);
            fetchJoinRequests(profile._id)

        } catch (error) {
            console.log(error)
        }
        setIsFetching(false)
    }

    const fetchInviteRequests = async function (playerId) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/players/${playerId || profile._id}/invites`
            );
            setInviteRequests(response.data);
        } catch (error) {
            console.log(error)
        }
    }

    const fetchInvites = async function () {
        await fetchInviteRequests();
        await fetchJoinRequests();
    }



    const updateProfileSettings = async ({ bio, type, instagram, twitter, isPublic, facebook, threads, avatar, games, city, country, gamerTags, zipcode, userState }) => {
        const filteredGames = games.filter(game => (game.name || game.gamerId || game.positions.length));
        const response = await axios.patch(
            `${process.env.REACT_APP_API_BASE_URL}/users/${user?._id}/players/${profile?._id}`,
            {
                bio, socials: { instagram: instagram || null, twitter: twitter || null, facebook: facebook || null, threads: threads || null }, avatar, games: filteredGames,
                locationZipCode: (country === "United States" ? zipcode : "") || null,
                locationCountryCode: country || null,
                locationState: country ? userState || null : null,
                locationCity: country && userState ? city || null : null,
                gamerTags,
                type,
                isPublic
            }
        );
        if (response.status === 200) {
            return response.data
        }
        return null
    }

    const updatePublicSettings = async (isPublic) => {
        await PlayerService.patchPublicSettingsById(user._id, profile._id, isPublic)
    }


    const handleAccept = async (id) => {
        try {
            await axios.patch(`/invites/${id}/accept`)
            enqueueSnackbar("Invite accepted.", {
                variant: "success",
                autoHideDuration: 2000,
            })
            fetchInvites()
        } catch (error) {
            enqueueSnackbar("Something went wrong, please try again.", {
                variant: "error",
                autoHideDuration: 2000,
            })
        }
    }

    const handleReject = async (id) => {
        try {
            await axios.patch(`/invites/${id}/reject`)
            enqueueSnackbar("Invite rejected.", {
                variant: "success",
                autoHideDuration: 2000,
            })
            fetchInvites()
        } catch (error) {
            enqueueSnackbar("Something went wrong, please try again.", {
                variant: "error",
                autoHideDuration: 2000,
            })
        }
    }

    const handleRevoke = async (id) => {
        try {
            await axios.patch(`/join-request/${id}/revoke`)
            enqueueSnackbar("Join request revoked.", {
                variant: "success",
                autoHideDuration: 2000,
            })
            fetchInvites()
        } catch (error) {
            enqueueSnackbar("Something went wrong, please try again.", {
                variant: "error",
                autoHideDuration: 2000,
            })
        }
    }

    return {
        profile,
        fetching,
        updateProfileSettings,
        updatePublicSettings,
        refetch: fetchProfile,
        videos,
        fetchInvites,
        handleAccept, 
        handleReject,
        handleRevoke,
        invites
    }
}

export default usePlayerProfile


import { useMemo } from "react";
// @mui
import { styled } from "@mui/material/styles";
import { Card, Typography, CardHeader, Stack} from "@mui/material";
// components
import Iconify from "src/components/Iconify";


const IconStyle = styled(Iconify)(({ theme }) => ({
    width: 20,
    height: 20,
    marginTop: 1,
    flexShrink: 0,
    marginRight: theme.spacing(2),
}));
export function PlayerAboutInfo({ player }) {
    const {
        discord = "",
        locationCountryCode = "",
        locationState = "",
        locationCity = "",
        userId: { email }
    } = player || {};


    const location = useMemo(() => {
        let locationDetails = "";
        if (locationCountryCode) {
            locationDetails += locationCountryCode;
        }
        if (locationState) {
            locationDetails = locationState + `, ` + locationDetails;
        }
        if (locationCity) {
            locationDetails = locationCity + `, ` + locationDetails;
        }
        return locationDetails;
    }, [locationCity, locationState, locationCountryCode]);

    return (
        <>
            <Card sx={{ position: "relative" }}>
                <CardHeader title="About" />

                <Stack spacing={2} sx={{ p: 3 }}>
                    {location && (
                        <Stack direction="row" alignItems={"center"}>
                            <IconStyle icon={"eva:pin-fill"} />
                            <Typography variant="body2">
                                <Typography
                                    component="span"
                                    sx={{ textDecoration: "none" }}
                                    variant="subtitle2"
                                    color="text.primary"
                                >
                                    Based in {location}
                                </Typography>
                            </Typography>
                        </Stack>
                    )}
                    {email && (
                        <Stack direction="row" alignItems={"center"}>
                            <IconStyle icon={"iconamoon:email"} />
                            <Typography variant="subtitle2">{email}</Typography>
                        </Stack>
                    )}
                    {discord && (
                        <Stack direction="row" alignItems={"center"}>
                            <IconStyle icon={"ic:twotone-discord"} />
                            <Typography variant="subtitle2">{discord}</Typography>
                        </Stack>
                    )}
                </Stack>
            </Card>
        </>
    );
}
import { useCallback, useEffect, useState } from "react";
import axiosInstance from "src/utils/axios";

const useUploads = () => {
    const userId = localStorage.getItem("_id");
    const [uploads, setUploads] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const getUserUploads = useCallback(async () => {
        if(!loading) {
            return;
        }
        try {
            //Fetch uploads by this user
            const uploads = await axiosInstance.get(
                `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/uploads`,
                {}
            );

            setUploads(uploads.data);
            setLoading(false);
        } catch (error) {
            setError(true);
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ userId]);

    useEffect(() => {
        getUserUploads();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);
    return {
        uploads,
        error,
        loading,
        reload: () => setLoading(true)
    }
   
}

export default useUploads
import { useSnackbar } from 'notistack';
import  { useCallback, useEffect, useState } from 'react'
import { UserService } from 'src/services/user';

const usePublicUser = (id) => {
    const [fetching, setFetching] = useState(true);
    const [profile, setProfile] = useState(true);
    const { enqueueSnackbar } = useSnackbar()

    const fetchUser = useCallback(async () => {
        const profile = await UserService.getPublicProfile(id);
        if (profile) {
            setProfile(profile);
        }
        else {
            enqueueSnackbar('Failed to fetch user profile', { variant: 'error' });
        }
        setFetching(false);
        // eslint-disable-next-line
    }, [id])

    useEffect(() => {
        fetchUser(id)
        // eslint-disable-next-line
    }, [id])
    return {
        fetching,
        profile
    }
}

export default usePublicUser
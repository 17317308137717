import React, { useEffect, useMemo, useState } from "react";
import axios from "../../utils/axios";
import {
  Container,
  Stack,
  CircularProgress,
  TablePagination,
  Box,
  TableBody,
  Table,
  Divider,
  TableContainer,
  Card,
  Collapse,
  Typography,
  TableCell,
  TableRow,
  Chip,
  Button,
  TextField,
  Autocomplete,
  Slider,
} from "@mui/material";
import useSettings from "../../hooks/useSettings";
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import usePlayersIManage from "src/hooks/usePlayersIManage";
import useTable, { getComparator } from "src/hooks/useTable";
import useTabs from "src/hooks/useTabs";
import { TableHeadCustom, TableNoData } from "src/components/table";
import Scrollbar from "src/components/Scrollbar";
import InvoiceAnalytic from "src/sections/invoice/InvoiceAnalytic";
import SearchToolbar from "src/sections/@dashboard/player/manage/SearchToolbar";
import axiosInstance from "src/utils/axios";
import Avatar from "src/components/Avatar";
import { useSnackbar } from "notistack";
import {
  RHFCheckbox,
  RHFTextField,
  FormProvider,
} from "src/components/hook-form";
import { useForm, Controller } from "react-hook-form";
import {
  fetchgamerTags,
  fetchGames,
  playerTypes,
} from "src/sections/@dashboard/user/account/PlayerAccountGeneral";
import LocationCard from "src/sections/@dashboard/user/account/OrganizationAccountGeneral/Location";
import useAuth from "src/hooks/useAuth";
import requestHeaders from "src/utils/restClient";

const TABLE_HEAD = [
  { id: "avatar", label: "Avatar", align: "left" },
  { id: "type", label: "Entity Type", align: "center" },
  { id: "name", label: "Name", align: "center" },
];

export default function Search() {
  const { user } = useAuth();
  const { themeStretch } = useSettings();
  const [results, setResults] = useState([]);
  const { players, isLoading } = usePlayersIManage();
  const [searchInitiated, setSearchInitiated] = useState(false); // New state to track search initiation
  const { enqueueSnackbar } = useSnackbar();
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    selected,
    onSelectAllRows,
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const [tableData, setTableData] = useState(players);
  const [filterName, setFilterName] = useState("");
  const { currentTab: filterStatus } = useTabs("all");

  const [openCard, setOpenCard] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setTableData(
      players.map((player) => {
        player.username = player.userId.username;
        player.email = player.userId.email;
        return player;
      })
    );
  }, [players]);

  const handleSearchFilter = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  // eslint-disable-next-line
  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterStatus,
  });

  // Function to toggle the visibility of cards
  const handleToggleCard = (card) => {
    setOpenCard((prev) => (prev === card ? null : card));
  };

  const handleSearch = async () => {
    if (!filterName) {
      enqueueSnackbar("Search query cannot be empty.", {
        variant: "error",
        autoHideDuration: 2000,
      });
      return;
    }
    // Set search initiated to true when the search button is pressed
    setIsSearching(true);
    setSearchInitiated(true);
    setOpenCard(null);
    // Search logic goes here
    const data = await axiosInstance.post("/search", {
      searchTerm: filterName,
    });
    setResults(data.data);
    setIsSearching(false);
  };

  // Search users form
  const defaultValues = {
    yearRange: [2016, 2018],
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
    mode: "onBlur",
  });

  const {
    // setValue,
    // handleSubmit,
    // watch,
    // setError,
    control,
    formState: { isSubmitting },
  } = methods;

  // Search players form
  const [gamerTags, setGamerTags] = useState([]);
  const [games, setGames] = useState([]);

  const appHeaders = requestHeaders(localStorage.getItem("accessToken"));
  const [desiredDegrees, setDesiredDegrees] = useState([]);
  const [desiredSchools, setDesiredSchools] = useState([]);
  const [desiredRegions, setDesiredRegions] = useState([]);
  const [collegeType, setCollegeType] = useState([]);
  const [collegeOptionsFetched, setCollegeOptionsFetched] = useState(false);
  const [dbTotalCounts, setDbTotalCounts] = useState(null);
  const [dbTotalCountsFetched, setDbTotalCountsFetched] = useState(false);

  // const years = useMemo(() => {
  //   const date = new Date();
  //   const year = date.getFullYear();
  //   return [year, year + 1, year + 2, year + 3, year + 4, year + 5, year + 6];
  // }, []);
  useEffect(() => {
    const fetchOptions = async () => {
      const endpoints = [
        "/colleges/types",
        "/colleges/degrees",
        "/colleges/regions",
        "/colleges/options",
      ];
      setCollegeOptionsFetched(true)
      const [types, degrees, regions, options] = await Promise.all(
        endpoints.map(async (endpoint) =>
          axios.get(`${process.env.REACT_APP_API_BASE_URL}${endpoint}`, {
            headers: appHeaders,
          })
        )
      );

      setCollegeType(types.data);
      setDesiredDegrees(degrees.data);
      setDesiredRegions(regions.data);
      setDesiredSchools(options.data);
    };
    if (!collegeOptionsFetched) {
      fetchOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appHeaders]);

  useEffect(() => {
    const fetchOptions = async () => {
      const endpoints = [
        "/search-total-counts",
      ];
      setDbTotalCountsFetched(true)
      const [totalCounts] = await Promise.all(
        endpoints.map(async (endpoint) =>
          axios.get(`${process.env.REACT_APP_API_BASE_URL}${endpoint}`, {
            headers: appHeaders,
          })
        )
      );
      setDbTotalCounts(totalCounts.data);
    };
    if (!dbTotalCounts && !dbTotalCountsFetched) {
      fetchOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appHeaders]);

  useEffect(() => {
    fetchgamerTags(setGamerTags);
    fetchGames(setGames);
  }, []);

  const filteredResult = useMemo(() => {
    return results
      .map((result) => ({
        type: result.model,
        name: result.username || result.name,
        avatar: result.avatarUrl,
        _id: result._id,
      }))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [results, rowsPerPage, page]);

  return (
    <Page title="Search">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading="Search" links={[]} />
        <SearchToolbar
          filterName={filterName}
          onFilterName={handleSearchFilter}
          handleSearch={handleSearch}
        />

        {dbTotalCounts && <Card sx={{ mb: 5 }}>
          <Scrollbar>
            <Stack
              direction="row"
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderStyle: "dashed" }}
                />
              }
              sx={{ py: 2 }}
            >
              <Box
                sx={{
                  flex: 1,
                  cursor: "pointer",
                  backgroundColor:
                    openCard === "players"
                      ? "rgba(90, 175, 226, 0.1)"
                      : "inherit", // Apply hover color when selected
                  "&:hover": {
                    backgroundColor: "rgba(90, 175, 226, 0.1)", // Light blue background on hover
                    transform: "scale(1.02)", // Slight zoom effect on hover
                    transition: "transform 0.2s, background-color 0.2s",
                  },
                }}
                onClick={() => handleToggleCard("players")}
              >
                {dbTotalCounts && (
                  <InvoiceAnalytic
                    title="Players"
                    total={dbTotalCounts.playersCount + " players"}
                    percent={100}
                    price={10}
                    icon="ion:game-controller"
                    color="#5AAFE2"
                  />
                )}
              </Box>
              <Box
                sx={{
                  flex: 1,
                  cursor: "pointer",
                  backgroundColor:
                    openCard === "users"
                      ? "rgba(164, 222, 114, 0.1)"
                      : "inherit", // Apply hover color when selected
                  "&:hover": {
                    backgroundColor: "rgba(164, 222, 114, 0.1)", // Light green background on hover
                    transform: "scale(1.02)", // Slight zoom effect on hover
                    transition: "transform 0.2s, background-color 0.2s",
                  },
                }}
                onClick={() => handleToggleCard("users")}
              >
                {dbTotalCounts && (
                  <InvoiceAnalytic
                    title="Users"
                    total={dbTotalCounts.usersCount + " users"}
                    percent={10}
                    price={10}
                    icon="fa-solid:user"
                    color="#A4DE72"
                  />
                )}
              </Box>
              <Box
                sx={{
                  flex: 1,
                  cursor: "pointer",
                  backgroundColor:
                    openCard === "teams"
                      ? "rgba(239, 218, 95, 0.1)"
                      : "inherit", // Apply hover color when selected
                  "&:hover": {
                    backgroundColor: "rgba(239, 218, 95, 0.1)", // Light yellow background on hover
                    transform: "scale(1.02)", // Slight zoom effect on hover
                    transition: "transform 0.2s, background-color 0.2s",
                  },
                }}
                onClick={() => handleToggleCard("teams")}
              >
                {dbTotalCounts && (
                  <InvoiceAnalytic
                    title="Teams"
                    total={dbTotalCounts.teamsCount + " teams"}
                    percent={10}
                    price={10}
                    icon="fa6-solid:user-group"
                    color="#EFDA5F"
                  />
                )}
              </Box>
              <Box
                sx={{
                  flex: 1,
                  cursor: "pointer",
                  backgroundColor:
                    openCard === "organizations"
                      ? "rgba(241, 148, 117, 0.1)"
                      : "inherit", // Apply hover color when selected
                  "&:hover": {
                    backgroundColor: "rgba(241, 148, 117, 0.1)", // Light orange background on hover
                    transform: "scale(1.02)", // Slight zoom effect on hover
                    transition: "transform 0.2s, background-color 0.2s",
                  },
                }}
                onClick={() => handleToggleCard("organizations")}
              >
                {dbTotalCounts && (
                  <InvoiceAnalytic
                    title="Organizations"
                    total={dbTotalCounts.organizationsCount + " organizations"}
                    percent={10}
                    price={10}
                    icon="fa-regular:building"
                    color="#F19475"
                  />
                )}
              </Box>
            </Stack>

            {/* Render the additional cards based on openCards state */}
            <Collapse in={openCard === "players"}>
              <Card
                sx={{
                  mt: 2,
                  p: 2,
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }}
              >
                <Stack direction="column" py={2} px={1} spacing={2}>
                  <Typography variant="h6">Players Filters</Typography>
                  <FormProvider
                    methods={methods} // You can reuse the same `methods` if both forms use the same validation schema
                    onSubmit={() => console.log("submit")}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        rowGap: 3,
                        columnGap: 2,
                        gridTemplateColumns: {
                          xs: "repeat(1, 1fr)",
                          sm: "repeat(2, 1fr)",
                        },
                        mb: 3,
                      }}
                    >
                      <RHFTextField name="gamerID" label="Gamer ID" />
                      <Controller
                        name={`type`}
                        control={control}
                        render={({
                          field: gamerTypeField,
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            multiple
                            limitTags={2}
                            id="player-type-tags"
                            options={playerTypes}
                            {...gamerTypeField}
                            getOptionLabel={(option) => option}
                            defaultValue={[]}
                            onChange={(_, data) =>
                              gamerTypeField.onChange(data)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Player Type"
                                error={!!error}
                                helperText={error?.message || ""}
                              />
                            )}
                          />
                        )}
                      />
                      <Controller
                        name={`games`}
                        control={control}
                        render={({
                          field: gamesField,
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            multiple
                            limitTags={2}
                            id="multiple-games"
                            options={games.map(({ name }) => name)}
                            {...gamesField}
                            getOptionLabel={(option) => option}
                            defaultValue={[]}
                            onChange={(_, data) => gamesField.onChange(data)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Games"
                                error={!!error}
                                helperText={error?.message || ""}
                              />
                            )}
                          />
                        )}
                      />
                      <Controller
                        sx={{ mt: 2, maxWidth: { md: "50%" } }}
                        name={`gamerTags`}
                        control={control}
                        render={({
                          field: gamerTagsField,
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            multiple
                            limitTags={2}
                            id="multiple-limit-tags"
                            options={gamerTags.map(({ name }) => name)}
                            {...gamerTagsField}
                            getOptionLabel={(option) => option}
                            defaultValue={[]}
                            onChange={(_, data) =>
                              gamerTagsField.onChange(data)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Gamer Tags"
                                error={!!error}
                                helperText={error?.message || ""}
                              />
                            )}
                          />
                        )}
                      />
                    </Box>
                    <LocationCard

                    // city={values.city}
                    // state={values.state}
                    // country={values.country}
                    />
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "500", mb: 2.5, ml: 1, mt: 2 }}
                    >
                      Text-Based Player Search
                    </Typography>
                    <RHFTextField
                      name="text-based-search-player"
                      label="Text-based search"
                      multiline
                      rows={4}
                      helperText={
                        user.goals.isCollegeTeamRecruitingPlayers &&
                        user.isVerifiedCollegeRep ? (
                          <Typography
                            variant="body2"
                            sx={{
                              mt: 1,
                              ml: -1,
                              //mx: 'auto',
                              display: "block",
                              textAlign: "left",
                              color: "text.secondary",
                            }}
                          >
                            Enter keywords to search across player bios and
                            college statements. This will help you find players
                            whose profiles and aspirations match your criteria.
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            sx={{
                              mt: 1,
                              ml: -1,
                              //mx: 'auto',
                              display: "block",
                              textAlign: "left",
                              color: "text.secondary",
                            }}
                          >
                            Enter any keywords to search within player bios.
                            This search will match your input with relevant text
                            in player profiles, helping you find the most
                            suitable players.
                          </Typography>
                        )
                      }
                    />
                    {user.goals.isCollegeTeamRecruitingPlayers &&
                      user.isVerifiedCollegeRep && (
                        <>
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: "500", mb: 2.5, ml: 1, mt: 2 }}
                          >
                            Player College Profile
                          </Typography>
                          <Box
                            sx={{
                              display: "grid",
                              rowGap: 3,
                              columnGap: 2,
                              gridTemplateColumns: {
                                xs: "repeat(1, 1fr)",
                                sm: "repeat(2, 1fr)",
                              },
                              mb: 3,
                            }}
                          >
                            <Stack px={1}>
                              <Typography>Graduation Year</Typography>
                              <Controller
                                name="yearRange"
                                control={control}
                                render={({ field }) => (
                                  <Slider
                                    {...field}
                                    min={2015}
                                    max={2020}
                                    step={1}
                                    valueLabelDisplay="auto"
                                    marks
                                    value={field.value} // Controlled by React Hook Form
                                    onChange={(_, newValue) =>
                                      field.onChange(newValue)
                                    } // Update state on change
                                    sx={{
                                      "& .MuiSlider-thumb": {
                                        "&:hover, &.Mui-focusVisible, &.Mui-active":
                                          {
                                            boxShadow:
                                              "0px 0px 0px 8px rgba(90, 175, 226, 0.16)",
                                          },
                                      },
                                      maxWidth: "95%",
                                      ml: 1,
                                    }}
                                  />
                                )}
                              />
                            </Stack>
                            <Stack px={1}>
                              <Typography>GPA</Typography>
                              <Controller
                                name="yearRange"
                                control={control}
                                render={({ field }) => (
                                  <Slider
                                    {...field}
                                    min={2015}
                                    max={2020}
                                    step={1}
                                    valueLabelDisplay="auto"
                                    marks
                                    value={field.value} // Controlled by React Hook Form
                                    onChange={(_, newValue) =>
                                      field.onChange(newValue)
                                    } // Update state on change
                                    sx={{
                                      "& .MuiSlider-thumb": {
                                        "&:hover, &.Mui-focusVisible, &.Mui-active":
                                          {
                                            boxShadow:
                                              "0px 0px 0px 8px rgba(90, 175, 226, 0.16)",
                                          },
                                      },
                                      maxWidth: "95%",
                                      ml: 1,
                                    }}
                                  />
                                )}
                              />
                            </Stack>
                            <Controller
                              name={`collegeType`}
                              control={control}
                              render={({
                                field: desiredCollegeType,
                                fieldState: { error },
                              }) => (
                                <Autocomplete
                                  multiple
                                  limitTags={2}
                                  id="multiple-limit-tags-college-type"
                                  options={collegeType.map(({ type }) => type)}
                                  {...desiredCollegeType}
                                  getOptionLabel={(option) => option}
                                  defaultValue={[]}
                                  onChange={(_, data) =>
                                    desiredCollegeType.onChange(data)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="College Type"
                                      error={!!error}
                                      helperText={error?.message || ""}
                                    />
                                  )}
                                />
                              )}
                            />
                            <Controller
                              name={`desiredDegree`}
                              control={control}
                              render={({
                                field: desiredDegreesField,
                                fieldState: { error },
                              }) => (
                                <Autocomplete
                                  multiple
                                  limitTags={2}
                                  id="multiple-limit-tags-college-degree"
                                  options={desiredDegrees.map(
                                    ({ name }) => name
                                  )}
                                  {...desiredDegreesField}
                                  getOptionLabel={(option) => option}
                                  defaultValue={[]}
                                  onChange={(_, data) =>
                                    desiredDegreesField.onChange(data)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Desired Degrees"
                                      error={!!error}
                                      helperText={error?.message || ""}
                                    />
                                  )}
                                />
                              )}
                            />
                            <Controller
                              name={`desiredSchools`}
                              control={control}
                              render={({
                                field: desiredSchoolsField,
                                fieldState: { error },
                              }) => (
                                <Autocomplete
                                  multiple
                                  limitTags={2}
                                  id="multiple-limit-tags-desired-schools"
                                  options={desiredSchools.map(
                                    ({ name }) => name
                                  )}
                                  {...desiredSchoolsField}
                                  getOptionLabel={(option) => option}
                                  defaultValue={[]}
                                  onChange={(_, data) =>
                                    desiredSchoolsField.onChange(data)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Desired Schools"
                                      error={!!error}
                                      helperText={error?.message || ""}
                                    />
                                  )}
                                />
                              )}
                            />
                            <Controller
                              name={`desiredRegions`}
                              control={control}
                              render={({
                                field: desiredRegionField,
                                fieldState: { error },
                              }) => (
                                <Autocomplete
                                  multiple
                                  limitTags={2}
                                  id="multiple-limit-tags-desired-region"
                                  options={desiredRegions.map(
                                    ({ name }) => name
                                  )}
                                  {...desiredRegionField}
                                  getOptionLabel={(option) => option}
                                  defaultValue={[]}
                                  onChange={(_, data) =>
                                    desiredRegionField.onChange(data)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Desired Region"
                                      error={!!error}
                                      helperText={error?.message || ""}
                                    />
                                  )}
                                />
                              )}
                            />
                          </Box>
                        </>
                      )}
                  </FormProvider>
                  <Button
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    sx={{ maxWidth: { md: "20%" } }}
                  >
                    Search Players
                  </Button>
                </Stack>
              </Card>
            </Collapse>

            <Collapse in={openCard === "users"}>
              <Card
                sx={{
                  mt: 2,
                  p: 2,
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }}
              >
                <Stack direction="column" py={2} px={1} spacing={2}>
                  <Typography variant="h6">Users Filters</Typography>
                  <FormProvider
                    methods={methods}
                    onSubmit={() => console.log("submit")}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        rowGap: 3,
                        columnGap: 2,
                        gridTemplateColumns: {
                          xs: "repeat(1, 1fr)",
                          sm: "repeat(2, 1fr)",
                        },
                        mb: 2,
                      }}
                    >
                      <RHFTextField
                        name="username"
                        label="Username"
                        inputProps={{ maxLength: 15 }}
                      />
                    </Box>
                    <RHFCheckbox
                      sx={{}}
                      name="isLookingForCollegeTeam"
                      label={
                        <Typography variant="body1" align="left" sx={{}}>
                          Users looking to join a college team?
                        </Typography>
                      }
                    />
                  </FormProvider>
                  <Button
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    sx={{ mt: 2, maxWidth: { md: "20%" } }}
                  >
                    Search Users
                  </Button>
                </Stack>
              </Card>
            </Collapse>

            <Collapse in={openCard === "teams"}>
              <Card
                sx={{
                  mt: 2,
                  p: 2,
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }}
              >
                <Stack direction="column" py={2} px={1} spacing={2}>
                  <Typography variant="h6">Teams Filters</Typography>
                  <FormProvider
                    methods={methods}
                    onSubmit={() => console.log("submit")}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        rowGap: 3,
                        columnGap: 2,
                        gridTemplateColumns: {
                          xs: "repeat(1, 1fr)",
                          sm: "repeat(2, 1fr)",
                        },
                        mb: 3,
                      }}
                    >
                      <RHFTextField name="teamName" label="Team Name" />

                      <Controller
                        name={`gamesPlayed`}
                        control={control}
                        render={({
                          field: gamesPlayedField,
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            multiple
                            limitTags={2}
                            id="multiple-games"
                            options={games.map(({ name }) => name)}
                            {...gamesPlayedField}
                            getOptionLabel={(option) => option}
                            defaultValue={[]}
                            onChange={(_, data) =>
                              gamesPlayedField.onChange(data)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Games Played"
                                error={!!error}
                                helperText={error?.message || ""}
                              />
                            )}
                          />
                        )}
                      />
                    </Box>
                    <LocationCard
                    // city={values.city}
                    // state={values.state}
                    // country={values.country}
                    />
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "500", mb: 2.5, ml: 1, mt: 2 }}
                    >
                      Text-Based Teams Search
                    </Typography>
                    <RHFTextField
                      name="text-based-search-teams"
                      label="Text-based search"
                      multiline
                      rows={4}
                      helperText={
                        <Typography
                          variant="body2"
                          sx={{
                            mt: 1,
                            ml: -1,
                            //mx: 'auto',
                            display: "block",
                            textAlign: "left",
                            color: "text.secondary",
                          }}
                        >
                          Enter keywords to search across team descriptions and
                          announcements. This will help you find teams whose
                          profiles and aspirations match your criteria.
                        </Typography>
                      }
                    />
                    <RHFCheckbox
                      sx={{ mt: 1 }}
                      name="isCollegeTeam"
                      label={
                        <Typography variant="body1" align="left" sx={{ mt: 1 }}>
                          Looking for college teams?
                        </Typography>
                      }
                    />
                  </FormProvider>
                  <Button
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    sx={{ mt: 2, maxWidth: { md: "20%" } }}
                  >
                    Search Teams
                  </Button>
                </Stack>
              </Card>
            </Collapse>

            <Collapse in={openCard === "organizations"}>
              <Card
                sx={{
                  mt: 2,
                  p: 2,
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }}
              >
                <Stack direction="column" py={2} px={1} spacing={2}>
                  <Typography variant="h6">Organizations Filters</Typography>
                  <FormProvider
                    methods={methods}
                    onSubmit={() => console.log("submit")}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        rowGap: 3,
                        columnGap: 2,
                        gridTemplateColumns: {
                          xs: "repeat(1, 1fr)",
                          sm: "repeat(2, 1fr)",
                        },
                        mb: 2,
                      }}
                    >
                      <RHFTextField
                        name="organizationName"
                        label="Organization Name"
                      />

                      <Controller
                        name={`gamesPlayedOrg`}
                        control={control}
                        render={({
                          field: gamesPlayedFieldOrg,
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            multiple
                            limitTags={2}
                            id="multiple-games"
                            options={games.map(({ name }) => name)}
                            {...gamesPlayedFieldOrg}
                            getOptionLabel={(option) => option}
                            defaultValue={[]}
                            onChange={(_, data) =>
                              gamesPlayedFieldOrg.onChange(data)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Games Played"
                                error={!!error}
                                helperText={error?.message || ""}
                              />
                            )}
                          />
                        )}
                      />

                      <Controller
                        name={`degreesOffered`}
                        control={control}
                        render={({
                          field: degreesOfferedField,
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            multiple
                            limitTags={2}
                            id="multiple-limit-tags-degrees-offered"
                            options={desiredDegrees.map(({ name }) => name)}
                            {...degreesOfferedField}
                            getOptionLabel={(option) => option}
                            defaultValue={[]}
                            onChange={(_, data) =>
                              degreesOfferedField.onChange(data)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Degrees Offered"
                                error={!!error}
                                helperText={error?.message || ""}
                              />
                            )}
                          />
                        )}
                      />
                    </Box>
                    <RHFCheckbox
                      sx={{ mb: 1 }}
                      name="scholarshipsOffered"
                      label={
                        <Typography variant="body1" align="left" sx={{ mb: 1 }}>
                          Scholarships offered?
                        </Typography>
                      }
                    />
                    <LocationCard
                    // city={values.city}
                    // state={values.state}
                    // country={values.country}
                    />

                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "500", mb: 2.5, ml: 1, mt: 2 }}
                    >
                      Text-Based Organizations Search
                    </Typography>
                    <RHFTextField
                      name="text-based-search-teams"
                      label="Text-based search"
                      multiline
                      rows={4}
                      helperText={
                        <Typography
                          variant="body2"
                          sx={{
                            mt: 1,
                            ml: -1,
                            //mx: 'auto',
                            display: "block",
                            textAlign: "left",
                            color: "text.secondary",
                          }}
                        >
                          Enter keywords to search across organization
                          descriptions, academic mission, admission
                          requirements, tuition amounts, scholarship
                          information, and announcements. This will help you
                          find organizations whose profiles match your criteria.
                        </Typography>
                      }
                    />
                  </FormProvider>
                  <Button
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    sx={{ mt: 2, maxWidth: { md: "20%" } }}
                  >
                    Search Organizations
                  </Button>
                </Stack>
              </Card>
            </Collapse>
          </Scrollbar>
        </Card>}

        {searchInitiated && (
          <Card>
            <Divider />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, position: "relative" }}>
                {isSearching ? ( // Show CircularProgress when loading
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{ minHeight: 400 }}
                  >
                    <CircularProgress sx={{ mb: 5, mt: 5 }} />
                  </Stack>
                ) : (
                  <Table size={dense ? "small" : "medium"}>
                    <TableHeadCustom
                      headLabel={TABLE_HEAD}
                      rowCount={tableData.length}
                      numSelected={selected.length}
                      onSort={onSort}
                      onSelectAllRows={(checked) =>
                        onSelectAllRows(
                          checked,
                          tableData.map((row) => row._id)
                        )
                      }
                    />

                    <TableBody>
                      {filteredResult.map(({ avatar, type, name, _id }) => (
                        <TableRow
                          sx={{cursor: 'pointer'}}
                          hover
                          key={_id}
                          onClick={() => {
                            window.open(
                              `/dashboard/public/${type.toLowerCase()}/${_id}`,
                              '_blank'
                            );
                          }}
                        >
                          <TableCell align="left">
                            <Avatar name={name} src={avatar} />
                          </TableCell>
                          <TableCell align="center">
                            {type && (
                              <Chip label={type} variant="filled"></Chip>
                            )}
                          </TableCell>
                          <TableCell align="center">{name}</TableCell>
                        </TableRow>
                      ))}

                      <TableNoData isNotFound={!isLoading && !results.length} />
                    </TableBody>
                  </Table>
                )}
              </TableContainer>

              {isLoading && (
                <Stack alignItems="center" justifyContent="center">
                  <CircularProgress sx={{ mb: 5, mt: 5 }} />
                </Stack>
              )}
            </Scrollbar>

            <Box sx={{ position: "relative" }}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={results.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />
            </Box>
          </Card>
        )}
      </Container>
    </Page>
  );
}

function applySortFilter({ tableData, comparator, filterName, filterStatus }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  // user name, email, team name, and games/positions
  if (filterName) {
    tableData = tableData.filter(
      (item) =>
        item.userId.username.toLowerCase().indexOf(filterName.toLowerCase()) !==
          -1 ||
        item.userId.email.toLowerCase().indexOf(filterName.toLowerCase()) !==
          -1 ||
        item.games
          .map((game) => game.name)
          .join(" ")
          .toLowerCase()
          .indexOf(filterName.toLowerCase()) !== -1 ||
        item.teams
          .map((game) => game.name)
          .join(" ")
          .toLowerCase()
          .indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== "all") {
    tableData = tableData.filter((item) => item.status === filterStatus);
  }

  return tableData;
}

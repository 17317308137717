import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Stack } from "@mui/material";
// utils
import cssStyles from "../../../../../utils/cssStyles";
import MyAvatar from "src/components/MyAvatar";
import Image from "src/components/Image";
// hooks
// components

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  "&:before": {
    ...cssStyles().bgBlur({ blur: 2, color: theme.palette.primary.darker }),
    top: 0,
    zIndex: 9,
    content: "''",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
}));

const InfoStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: "absolute",
  marginTop: theme.spacing(5),
  [theme.breakpoints.up("md")]: {
    right: "auto",
    display: "flex",
    alignItems: "center",
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

OrganizationCover.propTypes = {
  myProfile: PropTypes.object,
};

export default function OrganizationCover({
  title = "",
  subtitle = "",
  profile = "",
  cover = "",
  isPublicProfile = false,
  email=false
}) {
  return (
    <RootStyle>
      <InfoStyle
        sx={{
          // width: { md: "calc(100% - 50px)" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Stack flexDirection="row" alignItems={"center"}>
          <MyAvatar
            sx={{
              mx: "auto",
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: "common.white",
              width: { xs: 80, md: 128 },
              height: { xs: 80, md: 128 },
            }}
            profile={profile}
          />
          <Box
            sx={{
              ml: { md: 3 },
              mt: { xs: 1, md: 0 },
              color: "common.white",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            {title && <Typography variant="h4">{title} </Typography>}
            {subtitle && (
              <Typography sx={{ opacity: 0.72 }}>{subtitle}</Typography>
            )}

            
          </Box>
          
         
        </Stack>
       
       
        {isPublicProfile && email && email !== "" && (
          <Box >
            <Button
              position="absolute"
              sx={{  ml: {md:80} , mt: { xs: 4, md: 0 }, mr: 2 }}
              variant="contained"
              href={`mailto:${email}`}
            >
              Contact Organization
            </Button>
          </Box>
        )}
      </InfoStyle>
      <Image
        alt="profile cover"
        src={cover}
        sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
      />
    </RootStyle>
  );
}

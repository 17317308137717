import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}
function createBgGradient(){
  return 'radial-gradient(circle, hsla(270, 42%, 28%, 1) 0%, hsla(233, 20%, 18%, 1) 100%)';
}
// SETUP COLORS
const PRIMARY = {
  lighter: '#FECBD4',
  light: '#F9639E',
  main: '#ec008b',
  dark: '#A90085',
  darker: '#71006F',
};
const SECONDARY = {
  lighter: '#EBD9F9',
  light: '#B188DE',
  main: '#5A3493',
  dark: '#331A69',
  darker: '#180946',
};
const INFO = {
  lighter: '#C8ECFA',
  light: '#5AAFE2',
  main: '#0053A0',
  dark: '#002F73',
  darker: '#00174C',
};
const SUCCESS = {
  lighter: '#E9F9D1',
  light: '#A4DE72',
  main: '#44931C',
  dark: '#21690E',
  darker: '#0A4605',
};
const WARNING = {
  lighter: '#FCF6C9',
  light: '#EFDA5F',
  main: '#CCA600',
  dark: '#927100',
  darker: '#614800',
};
const ERROR = {
  lighter: '#FAC1A5',
  light: '#F19475',
  main: '#E36A52',
  dark: '#D12E1F',
  darker: '#960F1A',
};

const GREY = {
  0: '#FFFFFF',
  100: '#f5f5f5',
  200: '#eeeeee',
  300: '#ededed',
  400: '#bdbdbd',
  500: '#9e9e9e',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',
  500_8: alpha('#9e9e9e', 0.08),
  500_12: alpha('#9e9e9e', 0.12),
  500_16: alpha('#9e9e9e', 0.16),
  500_24: alpha('#9e9e9e', 0.24),
  500_32: alpha('#9e9e9e', 0.32),
  500_48: alpha('#9e9e9e', 0.48),
  500_56: alpha('#9e9e9e', 0.56),
  500_80: alpha('#9e9e9e', 0.8),
};

const GRADIENTS = {
  //primary: createGradient(PRIMARY.light, PRIMARY.main),
  primary: createBgGradient(),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: GREY[0], secondary: GREY[100], disabled: GREY[200] },
    background: { paper: '#262436', default: '#262436', neutral: '#262436' },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[200] },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
  },
};

export default palette;

import { Card, Stack, Typography } from '@mui/material'
import React from 'react'
import { RHFEditor } from 'src/components/hook-form'

const RHFEditorCard = ({title, subtitle, name, label}) => {
    return (
        <Card sx={{ p: 3, mt: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: "500", mb: 2, ml: 1 }}>
                {title}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, ml: 1 }}>
                {subtitle}
            </Typography>
            <Stack spacing={3} alignItems="flex-end" sx={{ my: 3 }}>
                <RHFEditor name={name} label={label} />
            </Stack>
        </Card>
    )
}

export default RHFEditorCard
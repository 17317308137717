import { useEffect, useState } from "react";
import useAuth from "./useAuth";
import axios from "src/utils/axios";

const usePublicPlayerProfile = (playerId) => {

    const { user } = useAuth()
    const [profile, setProfile] = useState();
    const [fetching, setIsFetching] = useState(true);
    // eslint-disable-next-line
    const [videos, setVideos] = useState([])

    useEffect(() => {
        fetchProfile()
        // eslint-disable-next-line
    }, [user?._id])

    const fetchProfile = async () => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: localStorage.getItem("accessToken"),
                },
            };
            const profile = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/players/${playerId}/public`,
                config
            );
            setProfile(profile.data)

        } catch (error) {
            console.log(error)
        }
        setIsFetching(false)
    }


    return {
        profile,
        fetching,
        videos,
        refetch: fetchProfile
    }
}

export default usePublicPlayerProfile
import { useCallback, } from "react";
import { Controller } from "react-hook-form";
import {
  Box,
  Card,
  Typography,
  // TextField,
  // Autocomplete,
  Button,
  Stack

} from "@mui/material";
import { fData } from "../../../../../utils/formatNumber";
import {
  RHFTextField,
  RHFUploadAvatar,
} from "../../../../../components/hook-form";


const EsportsCard = ({ control, setValue, values }) => {
  return (
    <Card sx={{ p: 3, mt: 3 }}>
      <Typography variant="h6" sx={{ fontWeight: "500", mb: 1, ml: 1 }}>
        E-Sports Titles
      </Typography>
      <Controller
        name={"esportsTitles"}
        control={control}
        render={({ field, fieldState }) => {
          return (
            <>
              <Box
                sx={{
                  display: "grid",
                  rowGap: 3,
                  columnGap: 2,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(3, 1fr)",
                  },
                  mt: 3,
                }}
              >
                <>
                  {field.value.map((_, index) => (
                    <ESportsOptionSelector
                      field={field}
                      index={index}
                      control={control}
                      {...fieldState}
                      setValue={setValue}
                    />
                  ))}
                </>
              </Box>
              {fieldState.error?.message && (
                <Typography sx={{ color: "error.main", mt: 1 }}>
                  {fieldState.error?.message}
                </Typography>
              )}
              <Stack>

              </Stack>
              <Stack alignContent="center" justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  color="success"
                  sx={{ mt: 2, color: "white" }}
                  onClick={(e) => {

                    field.onChange([
                      ...values.esportsTitles,
                      {
                        name: "",
                        logo: "",
                      },
                    ])
                  }
                  }
                >
                  {field.value.length ? "Add another title" : "Add a title"}
                </Button>
              </Stack>
            </>
          )
        }}
      ></Controller>
    </Card>
  )
}




export default EsportsCard;

const ESportsOptionSelector = ({
  field,
  index,
  setValue,
}) => {
  const handleRemove = () => {
    const filteredGames = field.value.filter((esport, i) => i !== index);
    field.onChange(filteredGames);
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          `esportsTitles.${index}.logoUrl`,
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  return (
    <Box
      sx={{
        display: "grid",
        rowGap: 2,
        columnGap: 2,
        gridTemplateColumns: { xs: "repeat(1, 1fr)" },
      }}
    >
      <RHFTextField name={`esportsTitles.${index}.name`} label="Name" />
      <Typography sx={{ ml: 1 }}>
        <strong>Upload Logo</strong>
      </Typography>
      <RHFUploadAvatar
        name={`esportsTitles.${index}.logoUrl`}
        accept="image/*"
        maxSize={1048576}
        onDrop={handleDrop}
        helperText={
          <Typography
            variant="caption"
            sx={{
              mt: 2,
              mx: "auto",
              display: "block",
              textAlign: "center",
              color: "text.secondary",
            }}
          >
            Allowed *.jpeg, *.jpg, *.png, *.gif, *.webp
            <br /> max size of {fData(1048576)}
          </Typography>
        }
      />
      {/* <Button variant="contained" component="label">
        Upload Logo
        <input name="esports-title-logo" type="file" accept="image/*" hidden />
      </Button> */}
      <Stack alignContent="center" justifyContent="center" alignItems="center">
        <Button
          sx={{ width: "50%", maxWidth: 150 }}
          variant="contained"
          color="error"
          onClick={handleRemove}
        >
          Remove
        </Button>
      </Stack>

    </Box>
  );
};

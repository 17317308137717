// @mui
import { styled } from "@mui/material/styles";
import { Box, Button, Stack, Typography } from "@mui/material";
// utils
import cssStyles from "src/utils/cssStyles";
// components
import Image from "src/components/Image";

import Avatar from "src/components/Avatar";
import AddToTeamDialog from "src/components/dialog/AddToTeamDialog";
import { useMemo, useState } from "react";
import AddToSquadDialog from "src/components/dialog/AddToSquadDialog";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  "&:before": {
    ...cssStyles().bgBlur({ blur: 2, color: theme.palette.primary.darker }),
    top: 0,
    zIndex: 9,
    content: "''",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
}));

const InfoStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: "absolute",
  marginTop: theme.spacing(5),
  [theme.breakpoints.up("md")]: {
    right: "auto",
    display: "flex",
    alignItems: "center",
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

export default function Cover({
  title = "",
  subtitle = "",
  profile = "",
  cover = "",
  player,
  refetchTeam,
  refetchSquad,
  teams,
  teamsList,
  squads, 
  squadsList
}) {
  const [open, setOpen] = useState(false);
  const [openSquad, setOpenSquad] = useState(false);
  const filteredTeams = useMemo(() => {
    let teamsMap = {};
    teams.forEach((team) => {
      teamsMap[team.id] = true;
    });
    return teamsList.filter((team) => !teamsMap[team._id]);
    // eslint-disable-next-line
  }, [teamsList, teams]);

  const filteredSquads = useMemo(() => {
    const squadsMap = {};
    squads.forEach((squad) => (squadsMap[squad._id] = true));
    return squadsList.filter((squad) => !squadsMap[squad._id]);
    // eslint-disable-next-line
  }, [squadsList, squads]);

  return (
    <RootStyle>
      <InfoStyle
        sx={{
          width: { md: "calc(100% - 50px)" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-between ",
        }}
      >
        <Stack flexDirection="row" alignItems={"center"}>
          <Avatar
            sx={{
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: "common.white",
              width: { xs: 80, md: 128 },
              height: { xs: 80, md: 128 },
            }}
            src={profile}
            name={title}
          />

          <Box
            sx={{
              ml: { md: 3 },
              mt: { xs: 1, md: 0 },
              color: "common.white",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            {title && <Typography variant="h4">{title} </Typography>}
            {subtitle && (
              <Typography sx={{ opacity: 0.72 }}>{subtitle}</Typography>
            )}
          </Box>
        </Stack>

        <Box>
          <Button
            sx={{ ml: { md: "auto" }, mt: { xs: 2, md: 0 }, mr: 2 }}
            variant="contained"
            onClick={() => setOpen(true)}
          >
            Invite to team
          </Button>
          <Button
            sx={{ ml: { md: "auto" }, mt: { xs: 2, md: 0 } }}
            variant="contained"
            onClick={() => setOpenSquad(true)}
          >
            Add to Squad
          </Button>
        </Box>
      </InfoStyle>
      <Image
        alt="profile cover"
        src={cover}
        sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
      />
      <AddToTeamDialog
        open={open}
        handleClose={() => setOpen(false)}
        teamsList={filteredTeams}
        player={player}
        refetch={refetchTeam}
      />
      <AddToSquadDialog
        open={openSquad}
        handleClose={() => setOpenSquad(false)}
        squadsList={filteredSquads}
        player={player}
        refetch={refetchSquad}
      />
    </RootStyle>
  );
}

import React, { useMemo } from "react";
import { useParams } from "react-router";
import Loader from "../../Loader";
import usePublicUser from "src/hooks/useUser";
import useSettings from "src/hooks/useSettings";
import { Page } from "@react-pdf/renderer";
import {
  AvatarGroup,
  Card,
  Chip,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import ProfileCover from "./cover";
import { Link as RouterLink } from "react-router-dom";
import Avatar from "src/components/Avatar";

const UserPublicProfile = () => {
  const { themeStretch } = useSettings();
  const { id } = useParams();
  const { profile, fetching } = usePublicUser(id);

  const title = useMemo(() => {
    if (profile?.user) {
      const { user } = profile;
      if (user.firstName || user.lastName) {
        return `${user.firstName} ${user.lastName}`;
      }
      return user.username;
    }
    return "";
    // eslint-disable-next-line
  }, [profile?.user]);

  return (
    <Loader loading={fetching}>
      <Page title="User Profile">
        <Container maxWidth={themeStretch ? false : "lg"}>
          <Card
            sx={{
              mb: 3,
              height: 280,
            }}
          >
            <ProfileCover
              profile={profile?.user?.avatarUrl}
              title={`${profile?.user?.firstName}  ${profile?.user?.lastName}`}
              subtitle={profile?.user?.username}
            />
          </Card>
          <Card
            cardStyle={{ backgroundColor: "transparent", shadowOpacity: 0 }}
            sx={{
              py: 3,
              px: 2,
              backgroundColor: "transparent", // Remove background
              boxShadow: "none", // Remove shadow
              border: "none",
            }}
          >
            <Typography variant="h6">User Profiles</Typography>
            {profile.organization && (
              <>
                <Typography sx={{ mt: 2 }} variant="subtitle1">
                  Organization
                </Typography>
                <OrganizationCard {...profile} />
              </>
            )}
            {profile.player && (
              <>
                <Typography sx={{ mt: 2 }} variant="subtitle1">
                  Player
                </Typography>
                <PlayerCard
                  {...profile}
                  title={title}
                  hasFullName={profile.user.firstName || profile.user.lastName}
                  username={profile.user.username}
                  avatar={profile?.user?.avatarUrl}
                />
              </>
            )}
            {profile.teams && profile.teams.length > 0 && (
              <>
                <Typography sx={{ mt: 2 }} variant="subtitle1">
                  Teams
                </Typography>
                {profile.teams.map((team) => (
                  <TeamViewCard
                    {...profile}
                    title={title}
                    team={team}
                    avatar={profile?.user?.avatarUrl}
                  />
                ))}
              </>
            )}
          </Card>
        </Container>
      </Page>
    </Loader>
  );
};

export default UserPublicProfile;

const TeamViewCard = ({ title, avatar, team }) => {
  return (
    <Card
      variant="elevation"
      sx={{
        p: 2,
        px: 4,
        mt: 2,
        display: "block",
        textDecoration: "none",
        cursor: "pointer",
      }}
      component={RouterLink}
      to={`/dashboard/public/team/${team?._id}`}
      target="_blank"
    >
      <Stack
        flexDirection={"row"}
        sx={{ mt: 2 }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack flexDirection={"row"} alignItems={"center"}>
          <Avatar name={team.name} src={team.avatarUrl} />
          <Typography variant="body1" sx={{ ml: 1 }}>
            {team.name}
          </Typography>
        </Stack>
        <AvatarGroup max={5}>
          {team.players?.slice(0, 5).map((player) => (
            <Avatar
              key={player._id}
              target="_blank"
              src={player.avatarUrl}
              disabledEffect
              name={player.userId.username}
              sx={{
                width: "30px",
                height: "30px",
                textDecoration: "none",
              }}
            />
          ))}
        </AvatarGroup>
        <Chip label="Team" />
      </Stack>
    </Card>
  );
};

const PlayerCard = ({ title, player, avatar, username, hasFullName }) => {
  return (
    <Card
      variant="elevation"
      sx={{
        p: 2,
        px: 4,
        mt: 2,
        display: "block",
        textDecoration: "none",
        cursor: "pointer",
      }}
      component={RouterLink}
      to={`/dashboard/public/player/${player?._id}`}
      target="_blank"
    >
      <Stack
        flexDirection={"row"}
        sx={{ mt: 2 }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack flexDirection={"row"} alignItems={"center"}>
          <Avatar name={title} src={avatar} />
          <Typography variant="body1" sx={{ ml: 1 }}>
            {title}
          </Typography>
          {hasFullName && (
            <Typography variant="body1" sx={{ ml: 0.5 }}>
              {"| " + username}
            </Typography>
          )}
        </Stack>
        <Chip label="Player" />
      </Stack>
    </Card>
  );
};

const OrganizationCard = ({ organization }) => (
  <Card
    variant="elevation"
    sx={{
      p: 2,
      px: 4,
      mt: 2,
      display: "block",
      textDecoration: "none",
      cursor: "pointer",
    }}
    component={RouterLink}
    to={`/dashboard/public/organization/${organization?._id}`}
    target="_blank"
  >
    <Stack
      flexDirection={"row"}
      sx={{ mt: 2 }}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Stack flexDirection={"row"} alignItems={"center"}>
        <Avatar name={organization?.name} src={organization?.avatarUrl} />
        <Typography variant="body1" sx={{ ml: 1 }}>
          {organization?.name}
        </Typography>
        <Typography variant="body1" sx={{ ml: 0.5 }}>
          {"| " + organization?.organizationType}
        </Typography>
      </Stack>
      <Chip label="Organization" />
    </Stack>
  </Card>
);

import { useEffect, useState } from 'react'
import { TeamService } from 'src/services/team';
import useAuth from './useAuth';

const usePublicTeam = (id) => {
    const { user } = useAuth()
    const [profile, setProfile] = useState();
    const [fetching, setIsFetching] = useState(true);
    useEffect(() => {
        fetchProfile(id)
        // eslint-disable-next-line
    }, [user?._id])



    const fetchProfile = async (id) => {
        try {

            const profile = await TeamService.getTeamPublicProfile(id);
            setProfile(profile)
        } catch (error) {
        }
        setIsFetching(false)
    }

    return {
        fetching,
        profile,
    }
}

export default usePublicTeam
import { PlayersTeamsInfo } from "./PlayersTeamsInfo";
import { PlayerAboutInfo } from "./PlayerAboutInfo";
import { PlayerSquadsInfo } from "./PlayerSquadInfo";
import useSquads from "src/hooks/useSquads";
import useTeams from "src/hooks/useTeams";

function Info({ player, teams, squads, refetch }) {
    const { squads: squadsList } = useSquads();
    const { teams: teamsList } = useTeams()
    return (
        <>
            <PlayerAboutInfo player={player} />
            <PlayersTeamsInfo teams={teams} player={player} refetch={refetch} teamsList={teamsList}/>
            <PlayerSquadsInfo squads={squads} player={player} refetch={refetch}  squadsList={squadsList}/>
        </>
    );
}
export default Info
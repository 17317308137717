import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Link,
  Card,
  Typography,
  CardHeader,
  Stack,
  Chip,
  Grid,
} from "@mui/material";
// components
import Iconify from "src/components/Iconify";
import { useMemo } from "react";
import Avatar from "src/components/Avatar";

// ----------------------------------------------------------------------

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------

PlayerProfileAbout.propTypes = {
  profile: PropTypes.object,
};

export default function PlayerProfileAbout({ profile }) {
  const {
    type = "",
    locationCountryCode = "",
    locationState = "",
    locationCity = "",
    gamerTags = [],
    games = [],
  } = profile || {};

  const location = useMemo(() => {
    let locationDetails = "";
    if (locationCountryCode) {
      locationDetails += locationCountryCode;
    }
    if (locationState) {
      locationDetails = locationState + `, ` + locationDetails;
    }
    if (locationCity) {
      locationDetails = locationCity + `, ` + locationDetails;
    }
    return locationDetails;
  }, [locationCity, locationState, locationCountryCode]);

  return (
    <>
      <Card>
        <CardHeader title="About" />

        <Stack spacing={2} sx={{ p: 3 }}>
          {location && (
            <Stack direction="row" alignItems={"center"}>
              <IconStyle icon={"eva:pin-fill"} />
              <Typography variant="body2">
                From
                <Link
                  component="span"
                  sx={{ ml: 1 }}
                  variant="subtitle2"
                  color="text.primary"
                >
                  {location}
                </Link>
              </Typography>
            </Stack>
          )}

          {type && (
            <Stack direction="row" alignItems={"center"}>
              <IconStyle icon={"mdi:medal-outline"} />
              <Typography variant="subtitle2">{type}</Typography>
            </Stack>
          )}

          {gamerTags.length ? (
            <Stack direction="row" alignItems={"center"}>
              <IconStyle icon={"mdi:tag-outline"} />
              <Typography variant="body2">
                <Link component="span" variant="subtitle2" color="text.primary">
                  {gamerTags.map((tag) => (
                    <Chip label={tag} sx={{ mr: 1, mb: 1 }}></Chip>
                  ))}
                </Link>
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </Card>
      <Card>
        <CardHeader title="Games I Play" />
        <Stack spacing={2} sx={{ p: 3 }}>
          {games.map((game) => (
            <GamePlay game={game} key={game._id}/>
           
          ))}
        </Stack>
      </Card >
    </>
  );
}


export const GamePlay = ({ game }) => {
  return <>
    <Grid container alignItems={"center"} flex flexDirection={"row"} >
      <Grid item>
        <Avatar name={game.name} />
      </Grid>
      <Grid item xs={10} sx={{ ml: 1 }}>
        <Stack spacing={1} alignItems={"start"}>
          <Typography variant="subtitle1">{game.name}</Typography>
          {game.gamerId && (
            <Link
              component="span"
              variant="subtitle2"
              color="text.primary"
              style={{
                whiteSpace: "wrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: 200
              }}
            >
              {"@" + game.gamerId}
            </Link>
          )}
        </Stack>
        <Typography variant="body2" marginTop={1}>

        </Typography>
      </Grid>
    </Grid>
    <Link
      component="span"
      variant="subtitle2"
      color="text.primary"
    >
      {game.positions.map((position) => (
        <Chip
          size="small"
          label={position}
          sx={{ mr: 1, mb: 1 }}
        ></Chip>
      ))}
    </Link>
  </>
}
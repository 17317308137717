import { useEffect, useMemo, useState } from "react";
import useAuth from "./useAuth";
import { TeamService } from "src/services/team";
import useOrganizationProfile from "./useOrganizationProfile";
import axios from "src/utils/axios";
import { useSnackbar } from "notistack";

const useTeam = (teamId, withRestrictions = false) => {
  // eslint-disable-next-line
  const [team, setTeam] = useState(null);
  const { user } = useAuth();
  const { profile: organization, fetching: organizationFetching } = useOrganizationProfile();
  const [fetching, setIsFetching] = useState(true);
  const { enqueueSnackbar } = useSnackbar()
  const [inviteRequests, setInviteRequests] = useState([]);
  const [joinRequests, setJoinRequests] = useState([])

  const invites = useMemo(() => {
    return inviteRequests.map(invite => ({ ...invite, type: "Invite" , title: `Invitation to Join Team ${invite.team?.name}`,  owner: true}))
  }, [inviteRequests])
 

  const joins = useMemo(() => {
    return joinRequests.map(join => ({ ...join, type: "Join Request" , title: `Request to Join Team ${join.team?.name} `}));
  }, [joinRequests])

  const fetchInviteRequests = async function (teamId) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/teams/${teamId || team._id}/invites`
      );
      setInviteRequests(response.data);
    } catch (error) {
      console.log(error)
    }
  }

  const fetchJoinRequests = async (teamId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/teams/${teamId || team._id}/join-requests`
      );
      setJoinRequests(response.data);
    } catch (error) {

    }
  }


  async function uploadEsportImages(userId, teamId, esportTitles) {
    return await Promise.all(
      esportTitles.map(async ({ name, logo, logoUrl }) => {
        if (logoUrl && typeof logoUrl !== "string") {
          const { fileLocation } = await TeamService.uploadFile(
            userId,
            teamId,
            logoUrl
          );
          return {
            name,
            logo: fileLocation,
          };
        }
        return {
          name,
          logo,
        };
      })
    );
  }

  const fetchTeam = async () => {
    if (!teamId) {
      setIsFetching(false);
      return;
    }
    const team = await (withRestrictions
      ? TeamService.getTeamWithRestrictions(teamId)
      : TeamService.getTeam(user, teamId));
    if (team) {
      setTeam(team);
      fetchInviteRequests(team._id);
      fetchJoinRequests(team._id)
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (organizationFetching) {
      fetchTeam();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id, organizationFetching]);


  const fetchInvites = async function () {
    await fetchInviteRequests();
    await fetchJoinRequests();
  }


  const updateTeam =
    async (teamId, values, esportTitles, avatar) => {
      await TeamService.updateProfile(user?._id, teamId, {
        isPublic: values.isPublic,
        featuredOnProfile: values.featuredOnProfile,
        gamesPlayedByTeam: values.gamesPlayedByTeam.map(({ _id }) => _id),
        description: values.description,
        name: values.name,
        esportsTitles: esportTitles,
        locationState: values.locationState,
        avatar,
        locationCity: values.locationCity,
        ...(values.locationZipCode && {
          locationZipCode: values.locationZipCode,
        }),
        locationCountryCode: values.locationCountryCode,
        socials: values.socials,
        privateNotes: values.privateNotes,
        announcement: values.announcement,
      });
      await fetchTeam();
    }



  const postTeam = async (values) => {
    const team = await TeamService.createProfile(user?._id, {
      isPublic: values.isPublic,
      featuredOnProfile: values.featuredOnProfile,
      gamesPlayedByTeam: values.gamesPlayedByTeam.map(({ _id }) => _id),
      description: values.description,
      name: values.name,
      esportsTitles: [],
      locationState: values.locationState,
      locationCity: values.locationCity,
      ...(values.locationZipCode && {
        locationZipCode: values.locationZipCode,
      }),
      locationCountryCode: values.locationCountryCode,
      socials: values.socials,
      linkedOrg: organization?._id,
      privateNote: values.privateNotes,
      announcement: values.announcement,
    });
    let avatar = "";
    const esportTitles = await uploadEsportImages(
      user._id,
      team._id,
      values.esportsTitles
    );
    if (values.photoURL?.path) {
      const { fileLocation } = await TeamService.uploadAvatar(
        user._id,
        team._id,
        values.photoURL
      );
      avatar = fileLocation;
    }

    await updateTeam(team._id, values, esportTitles, avatar);
  };

  const patchTeam = async (values) => {
    let { avatar, photoURL } = values;
    if (avatar && photoURL?.path) {
      //delete the previous image
      await TeamService.deleteAvatar(user._id, team._id, team.avatar);
      const { fileLocation } = await TeamService.uploadAvatar(
        user._id,
        team._id,
        values.photoURL
      );
      avatar = fileLocation;
    }
    const esportTitles = await uploadEsportImages(
      user._id,
      team._id,
      values.esportsTitles
    );
    await updateTeam(team._id, values, esportTitles, avatar);
  };

  const submit = async (values) => {
    if (teamId) {
      await patchTeam(values);
    } else {
      await postTeam(values);
    }
  };

  const deleteTeam = async () => {
    if (teamId) {
      await TeamService.deleteTeam(user._id, teamId);
    }
  };


  const handleAccept = async (id) => {
    try {
      await axios.patch(`/join-request/${id}/accept`)
      enqueueSnackbar("Join request accepted.", {
        variant: "success",
        autoHideDuration: 2000,
      })
      fetchInvites()
    } catch (error) {
      enqueueSnackbar("Something went wrong, please try again.", {
        variant: "error",
        autoHideDuration: 2000,
      })
    }
  }

  const handleReject = async (id) => {
    try {
      await axios.patch(`/join-request/${id}/reject`)
      enqueueSnackbar("Join request rejected.", {
        variant: "success",
        autoHideDuration: 2000,
      })
      fetchInvites()
    } catch (error) {
      enqueueSnackbar("Something went wrong, please try again.", {
        variant: "error",
        autoHideDuration: 2000,
      })
    }
  }

  const handleRevoke = async (id) => {
    try {
      await axios.patch(`/invites/${id}/revoke`)
      enqueueSnackbar("Invite revoked.", {
        variant: "success",
        autoHideDuration: 2000,
      })
      fetchInvites()
    } catch (error) {
      enqueueSnackbar("Something went wrong, please try again.", {
        variant: "error",
        autoHideDuration: 2000,
      })
    }
  }

  return {
    fetching,
    team,
    submit,
    deleteTeam,
    organization,
    organizationFetching,
    invites,
    handleAccept,
    handleReject,
    handleRevoke,
    joins,
    refetch: fetchTeam
  };
};

export default useTeam;

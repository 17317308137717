import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
import { TextField } from "@mui/material";


RHFTextFieldMultiline.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextFieldMultiline({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextField
            {...field}
            multiline
            rows={10}
            fullWidth
            error={!!error}
            helperText={error?.message}
            {...other}
          />
        )
      }}
    />
  );
}

import axiosInstance from "src/utils/axios";

export class SquadService {
    static getHeaders() {
        return {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: localStorage.getItem("accessToken"),
            },
        };
    }

    static async getSquads(userId) {
        try {
            const response = await axiosInstance.get(
                `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/squads`,
                SquadService.getHeaders()
            );
            if (response.status === 200) {
                return response.data
            }

        } catch (error) {

        }
        return null
    }

    static async getSquad(user, squadId) {
        try {
            const response = await axiosInstance.get(
                `${process.env.REACT_APP_API_BASE_URL}/users/${user._id}/Squads/${squadId}`,
                SquadService.getHeaders()
            );
            if (response.status === 200) {
                return response.data
            }

        } catch (error) {

        }
        return null
    }

    static async createSquad(userId, values) {
            const response = await axiosInstance.post(
                `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/squads`, { ...values },
                {
                    headers: SquadService.getHeaders()
                }
            );
            if (response.status === 201) {
                return response.data
            }
    }

    static async updateSquad(userId, squadId, values) {
        const response = await axiosInstance.patch(
            `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/squads/${squadId}`, { ...values },
            {
                headers: SquadService.getHeaders()
            }
        );
        if (response.status === 200) {
            return response.data
        }

    }
}
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Chip, Typography } from '@mui/material';
// routes
// utils
// components
import Avatar from '../../../components/Avatar';
import { formatDate } from 'fullcalendar';
import { useMemo } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: 84,
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
  justifyContent: 'space-between',
}));

// ----------------------------------------------------------------------

MailDetailsToolbar.propTypes = {
  mail: PropTypes.object,
};

export default function MailDetailsToolbar({ mail, ...other }) {
  const statusColor = useMemo(() => {
    switch (mail.status) {
      case 'Pending':
        return 'warning';
      case 'Accepted':
        return 'success';
      case 'Rejected':
        return 'error';
      default:
        return 'default';
    }
  }, [mail.status])

  return (
    <RootStyle {...other}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={mail.name} src={mail.avatar || ''} name={mail.name}>
        </Avatar>

        <Box sx={{ ml: 2 }}>
          <Typography display="inline" variant="subtitle2">
            {mail.name}
          </Typography>
        </Box>
      </Box>
      <Box flexDirection={"row"} alignItems={"center"}>
        <Chip color={statusColor} size='small' label={mail.status} sx={{ mr: 2 }}></Chip>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {mail.createdAt && formatDate(mail.createdAt)}
        </Typography>
      </Box>

    </RootStyle>
  );
}

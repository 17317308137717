import { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
// components
import Iconify from "src/components/Iconify";
import Editor from "src/components/editor";
import { FormProvider } from "src/components/hook-form";
import { Controller, useForm } from "react-hook-form";
import { Validation } from "src/utils/validations";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  GameOptionSelector,
  fetchGames,
} from "../../user/account/PlayerAccountGeneral";

ManagePlayer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default function ManagePlayer({
  isOpen,
  player,
  onClose,
  onSave,
  defaultnotes,
  playerPositions,
}) {
  const [notes, setNotes] = useState("");
  const [games, setGames] = useState([]);

  useEffect(() => {
    if (player) {
      setNotes(
        defaultnotes?.find((note) => note.playerId === player._id)?.notes ?? ""
      );
      setValue(
        "privateNotes",
        defaultnotes?.find((note) => note.playerId === player._id)?.notes ?? ""
      );

      if (playerPositions?.positionsList) {
        setValue(
          "games",
          playerPositions.positionsList.map((pos) => ({
            id: pos.game._id,
            name: pos.game.name,
            positions: pos.positions,
          }))
        );
      } else {
        setValue("games", []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  useEffect(() => {
    fetchGames(setGames);
  }, []);

  const defaultValues = {
    games: [],
    privateNotes: notes,
  };

  const methods = useForm({
    resolver: yupResolver(Validation.ManagePlayerValidationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const { setValue, handleSubmit, watch, control } = methods;

  const values = watch();

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={() => onClose(notes)}
        PaperProps={{
          sx: { width: { xs: "100%", sm: 400 } },
        }}
      >
        <FormProvider
          methods={methods}
          onSubmit={handleSubmit(() => onSave(values))}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ px: 1, py: 2 }}
          >
            <Typography variant="subtitle1" sx={{ ml: 1 }}>
              Manage Player | @{player?.userId?.username ?? ""}
            </Typography>
            <IconButton onClick={() => onClose(notes)}>
              <Iconify icon={"eva:close-fill"} width={20} height={20} />
            </IconButton>
          </Stack>

          <Divider />

          <Stack
            spacing={2}
            sx={{
              p: 3,
            }}
          >
            <Typography variant="subtitle1">Player Positions</Typography>
            <Controller
              name={"games"}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <Box
                    sx={{
                      display: "grid",
                      rowGap: 3,
                      columnGap: 2,
                      gridTemplateColumns: {
                        xs: "repeat(1, 1fr)",
                      },
                      mt: 3,
                    }}
                  >
                    <>
                      {field.value.map((game, index) => (
                        <GameOptionSelector
                          key={game.id || index}
                          field={field}
                          games={games}
                          index={index}
                          control={control}
                          noGameId
                          {...fieldState}
                        />
                      ))}
                    </>
                  </Box>
                  {fieldState.error?.message && (
                    <Typography sx={{ color: "error.main", mt: 1 }}>
                      {fieldState.error?.message}
                    </Typography>
                  )}
                  <Stack
                    alignContent="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ mt: 2, color: "white" }}
                      onClick={(e) =>
                        field.onChange([
                          ...field.value,
                          {
                            game: "",
                            positions: [],
                          },
                        ])
                      }
                    >
                      {field.value.length ? "Add another game" : "Add a game"}
                    </Button>
                  </Stack>
                </>
              )}
            ></Controller>
          </Stack>

          <Stack
            spacing={2}
            sx={{
              p: 3,
              "& .ql-editor": {
                height: "min(40vh, 500px)",
              },
            }}
          >
            <Typography variant="subtitle1">Private Notes</Typography>
            <Editor
              simple
              id="private-notes"
              value={notes}
              onChange={(val) => {
                setNotes(val);
                setValue("privateNotes", val);
              }}
              placeholder="Add private notes..."
            />
          </Stack>

          <Box sx={{ p: 3 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              onClick={() => onSave(values)}
              startIcon={<Iconify icon={"carbon:save"} />}
            >
              Save changes
            </Button>
          </Box>
        </FormProvider>
      </Drawer>
    </>
  );
}

import { Stack } from "@mui/material";


export default function UploadMediaContent({ upload, videoUrl }) {

  return (
    <>
      {upload !== null && videoUrl !== null && (
        <>
        <Stack sx={{p: 2, aspectRatio: 2}} >
          <video width="100%" height={"100%"} controls controlsList="nodownload"  >
            <source  src={videoUrl} type="video/mp4" />
          </video>
        </Stack>
        </>
      )}
    </>
  );
}

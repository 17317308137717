import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, DialogContent, Typography } from "@mui/material";

export default function LeaveDialog({ open, setOpen, name }) {
  const handleClose = () => {
    setOpen({ open: false, func: null });
  };

  const [loading, setLoading] = React.useState(false);

  return (
    <React.Fragment>
      <Dialog
        open={open.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to remove yourself from the team {name}? This
          action cannot be undone, and you will lose access to team
          communications and resources.
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 3, ml: 2 }}>
            <ul>
              <li>Your team will be notified of your departure.</li>
              <li>
                You will no longer be able to participate in team events and
                activities.
              </li>
            </ul>
          </Box>
          <Typography sx={{ mt: 2 }}>Do you wish to proceed?</Typography>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={loading}
            onClick={async () => {
              setLoading(true);
              open.func && (await open.func());
              setLoading(false);
              handleClose();
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { Card, Typography, CardHeader, Stack, Chip, Link } from "@mui/material";
// components
import Iconify from "src/components/Iconify";
import { useMemo } from "react";

// ----------------------------------------------------------------------

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------

About.propTypes = {
  profile: PropTypes.object,
};

export default function About({ organization }) {
  const {
    email = "",
    phone = "",
    website = "",
    discord = "",
    locationCountryCode = "",
    locationState = "",
    locationCity = "",
  } = organization || {};

  const location = useMemo(() => {
    let locationDetails = "";
    if (locationCountryCode) {
      locationDetails += locationCountryCode;
    }
    if (locationState) {
      locationDetails = locationState + `, ` + locationDetails;
    }
    if (locationCity) {
      locationDetails = locationCity + `, ` + locationDetails;
    }
    return locationDetails;
  }, [locationCity, locationState, locationCountryCode]);

  return (
    <>
      <Card sx={{ position: "relative" }}>
        <CardHeader title="About" />
        {organization?.scholarshipsOffered && (
          <Chip
            label="Scholarships Available"
            sx={{ position: "absolute", top: 10, right: 10, color: "white" }}
            color="success"
          />
        )}
        <Stack spacing={2} sx={{ p: 3 }}>
          {location && (
            <Stack direction="row" alignItems={"center"}>
              <IconStyle icon={"eva:pin-fill"} />
              <Typography variant="body2">
                <Typography
                  component="span"
                  sx={{ textDecoration: "none" }}
                  variant="subtitle2"
                  color="text.primary"
                >
                  Based in {location}
                </Typography>
              </Typography>
            </Stack>
          )}
          {email && (
            <Stack direction="row" alignItems={"center"}>
              <IconStyle icon={"iconamoon:email"} />
              <Typography variant="subtitle2">{email}</Typography>
            </Stack>
          )}
          {phone && (
            <Stack direction="row" alignItems={"center"}>
              <IconStyle icon={"ri:phone-line"} />
              <Typography variant="subtitle2">{phone}</Typography>
            </Stack>
          )}
          {website && (
            <Stack direction="row" alignItems={"center"}>
              <IconStyle icon={"gg:website"} />
              <Link
                component="a"
                variant="subtitle2"
                color="text.primary"
                noWrap
                href={website}
                target="_blank"
              >
                {website}
              </Link>
            </Stack>
          )}
          {discord && (
            <Stack direction="row" alignItems={"center"}>
              <IconStyle icon={"ic:twotone-discord"} />
              <Typography variant="subtitle2">{discord}</Typography>
            </Stack>
          )}
        </Stack>
      </Card>
    </>
  );
}

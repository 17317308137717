import React, { useMemo, useState } from "react";
import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import { SearchBar } from "src/sections/@dashboard/mail/SearchBar";
import RequestDetails from "src/sections/@dashboard/mail/InviteDetails";
import InviteJoinSidebar from "src/sections/@dashboard/mail/InviteJoinSidebar";

// ----------------------------------------------------------------------

export default function JoinTeamRequests({
  requests,
  handleAccept,
  handleReject,
  handleRevoke,
  label,
  searchLabel,
}) {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openCompose, setOpenCompose] = useState(false);
  const [search, setSearch] = useState("");
  const sortedRequests = useMemo(() => {
    const pendingSorted = requests.sort((a, b) => {
      if (a.status === "Pending" && b.status !== "Pending") {
        return -1;
      }
      if (a.status !== "Pending" && b.status === "Pending") {
        return 1;
      }
      return 0;
    });
    const lowerCaseSearch = search.toLowerCase();
    return pendingSorted.filter((invite) => {
      return (
        invite.relatedTeam?.name?.toLowerCase().includes(lowerCaseSearch) ||
        invite.sender?.userId?.username
          ?.toLowerCase()
          .includes(lowerCaseSearch) ||
        invite.status.toLowerCase().includes(lowerCaseSearch)
      );
    });
  }, [requests, search]);

  const [selectedRequest, setSelectedRequest] = useState(sortedRequests[0]);

  return (
    <>
      <HeaderBreadcrumbs sx={{ mt: 4, mb: -1 }} heading={label} />
      <Card sx={{ height: { md: "72vh" }, overflowY: 'auto'  }}>
        <SearchBar
          search={search}
          setSearch={setSearch}
          placeholder={searchLabel}
        />
        <Divider />
        <Stack flexDirection={"row"} height="100%">
          <InviteJoinSidebar
            openCompose={openCompose}
            isOpenSidebar={openSidebar}
            search={search}
            setSearch={setSearch}
            onCloseSidebar={() => setOpenSidebar(false)}
            onOpenCompose={() => setOpenCompose(true)}
            requests={sortedRequests}
            selectRequest={setSelectedRequest}
            selectedRequest={selectedRequest}
            
          />
          {selectedRequest ? (
            <RequestDetails
              request={selectedRequest}
              handleAccept={handleAccept}
              handleReject={handleReject}
              handleRevoke={handleRevoke}
              setSelcted={setSelectedRequest}
            />
          ) : null}

          {requests.length === 0 && <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Typography sx={{mt: {md: -20, xs: 3}, mb: {xs: 3}}} variant="h5">No pending requests.</Typography>
            </Stack>
          </Box>}
        </Stack>
      </Card>
    </>
  );
}

// @mui
import {
  Container,
  Typography,
  Stack,
  CircularProgress,
  Grid,
  Card,
  Box,
  Chip,
} from "@mui/material";
// routes
import useSettings from "../../hooks/useSettings";
// components
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
// sections
import { useOrgTeams } from "src/hooks/useTeams";
import Avatar from "src/components/Avatar";

// ----------------------------------------------------------------------

export default function OrganizationPlayers({ organization }) {
  const { themeStretch } = useSettings();
  const { teams, isLoading } = useOrgTeams(organization._id);

  return (
    <Page title="Ecruit.gg | Featured Players">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          moreLink={null}
          sx={{ mt: 4, mb: 1 }}
          heading="Featured Players"
        />

        <Grid container spacing={3} sx={{ mt: 2 }}>
          {teams?.featuredPlayers?.length
            ? teams?.featuredPlayers?.map((player) => (
                <Grid key={player._id} item xs={12} sm={6} md={4}>
                  <Player player={player} />
                </Grid>
              ))
            : ""}
        </Grid>

        {isLoading && (
          <Stack alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}

        {!teams?.featuredPlayers?.length && !isLoading && (
          <Typography variant="subtitle1" sx={{ textAlign: "center", mt:2 }}>
            This organization does not have any featured players.
          </Typography>
        )}
      </Container>
    </Page>
  );
}

export const Player = ({ player }) => {
  return (
    <Card
      sx={{
        // bgcolor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
        borderRadius: "16px",
        //boxShadow: 2,
        color: "black",
        aspectRatio: "1/1",
        textAlign: "center",
        px: 3,
        position: "relative",
      }}
    >
      <Avatar
        alt={player.userId.username}
        src={player.avatarUrl}
        sx={{ width: 80, height: 80 }}
        name={player.userId.username}
      />

      <Typography sx={{ color: "white" }} variant="h5">
        {player.userId.username}
      </Typography>

      <Box
        sx={{
          mt: 1.8,
          display: "flex",
          flexWrap: "wrap",
          gap: "5px",
          fontSize: "12px",
          justifyContent: "center",
          height: "72px",
          overflow: "hidden",
        }}
      >
        {player &&
          player.games.map((game) => (
            <Chip
              sx={{
                fontSize: "14px",
                backgroundColor: "#4c13ac",
                color: "white",
                fontWeight: 500,
              }}
              label={[
                game.name,
                game.positions.length
                  ? game.positions.slice(0, 2).join(", ")
                  : null,
              ]
                .filter((val) => val)
                .join(" - ")}
            ></Chip>
          ))}
      </Box>
    </Card>
  );
};

import { useCallback, useEffect, useState } from "react";
import useAuth from "./useAuth";
import { TeamService } from "src/services/team";
const useTeams = () => {
  const [teams, setTeams] = useState([]);
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const fetchTeams = useCallback(async function () {
    const profiles = await TeamService.getTeams(user);
    setTeams(profiles);
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    fetchTeams();
    // eslint-disable-next-line
  }, [user?._id]);
  return {
    teams,
    isLoading,
  };
};

export const useOrgTeams = (orgId) => {
  const [teams, setTeams] = useState([]);
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const fetchTeams = useCallback(async function () {
    const profiles = await TeamService.getOrganizationTeams(user, orgId);
    setTeams(profiles);
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    fetchTeams();
    // eslint-disable-next-line
  }, [user?._id]);
  return {
    teams,
    isLoading,
  };
};

export default useTeams;

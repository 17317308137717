import { CircularProgress, Stack } from '@mui/material'
import React from 'react'

const Loader = ({ loading, children }) => {
  if (loading) {
    return (<Stack alignItems="center" justifyContent="center">
      <CircularProgress />
    </Stack>)
  }
  return (
    <>{children}</>
  )
}

export default Loader
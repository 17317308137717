import { Box, Grid, Card, Typography } from '@mui/material'
import React from 'react'
import { RHFTextField } from 'src/components/hook-form'

const Info = ({announcement}) => {

  return (
    <Grid item xs={12} md={8}>
    <Card sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ fontWeight: "500", mb: 2, ml: 1 }}>
        Info
      </Typography>
      <Box
        sx={{
          display: "grid",
          rowGap: 3,
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
          },
        }}
      >
      <RHFTextField name="name" label="Name"/>
        <RHFTextField
          name="announcement"
          label="Announcement"
          multiline
          rows={4}
          helperText={
            <Typography
              variant="body2"
              sx={{
                mt: 1,
                ml: -1,
                //mx: 'auto',
                display: "block",
                textAlign: "left",
                color: "text.secondary",
              }}
            >
             {announcement}
            </Typography>
          }
        />
      </Box>
    </Card>
  </Grid>
  )
}

export default Info
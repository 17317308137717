import PropTypes from "prop-types";
import About from "./About";
import EsportsTitles from "./EsportsTitles";
import Socials from "./Socials";

Info.propTypes = {
  team: PropTypes.object,
};

export default function Info({ team }) {
  return (
    <>
      <About team={team} />
      <EsportsTitles esportsTitles={team?.esportsTitles} />
      <Socials socials={team?.socials} />
    </>
  );
}

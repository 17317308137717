import useAuth from "./useAuth";
import { useCallback, useEffect, useState } from "react";
import { TeamService } from "src/services/team";

const usePlayersIManage = () => {
    const [players, setPlayers] = useState([]);
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(true);


    const fetchPlayersIManage = useCallback(async function () {
        const players = await TeamService.getPlayersIManage(user._id);
        setPlayers(players || [])
        setIsLoading(false);
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        fetchPlayersIManage();
        // eslint-disable-next-line
    }, [user?._id])
    return {
        players,
        isLoading
    }
}

export default usePlayersIManage
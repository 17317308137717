import axios from "src/utils/axios";

export class UserService {
  static getHeaders() {
    return {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("accessToken"),
      },
    };
  }
  static async getPublicProfile(id) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/${id}/public`,
        {
          headers: UserService.getHeaders(),
        }
      );
      return response.data
      
    } catch (error) {
      console.error(error);
    }
  }
  
  
}

import { SquadService } from "src/services/squad";
import useAuth from "./useAuth";
import { useCallback, useEffect, useState } from "react";

const useSquads = () => {
    const [squads, setSquads] = useState([]);
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(true);


    const fetchsquads = useCallback(async function () {
        const squads = await SquadService.getSquads(user._id);
        setSquads(squads || [])
        setIsLoading(false);
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        fetchsquads();
        // eslint-disable-next-line
    }, [user?._id])
    return {
        squads,
        isLoading
    }
}

export default useSquads
import  { useEffect, useState } from 'react'
import { UtilService } from 'src/services/util'

const useOfferedDegrees = () => {
    const [offeredDegrees, setDegreesOffered] = useState([])
    useEffect(() => {
        (async () => {
            const degrees  = await UtilService.fetchOfferedDegress()
            if(degrees) {
                setDegreesOffered(degrees)
            }
        })()
    }, [])
    return {
        offeredDegrees
    }
}

export default useOfferedDegrees
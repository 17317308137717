import PropTypes from "prop-types";
import { useState} from "react";
import { styled } from "@mui/material/styles";
import {
    Box,
    Card,
    Typography,
    CardContent,
    Container,
    Button,
    Stack,
    CircularProgress,
    Grid,
} from "@mui/material";
import Iconify from "../../../components/Iconify";
import LightboxModal from "../../../components/LightboxModal";
import { MotionContainer, varBounce } from "src/components/animate";
import { m } from "framer-motion";
import UploadMediaContent from "../../../sections/@dashboard/uploads/UploadMediaContent";
import UploadDetailsSummary from "../../../sections/@dashboard/uploads/UploadDetailsSummary";

const CaptionStyle = styled(CardContent)(({ theme }) => ({
    top: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    justifyContent: "space-between",
    color: theme.palette.common.white,
}));

const ContentStyle = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
}));

PlayerVideoGallery.propTypes = {
    gallery: PropTypes.array.isRequired,
};

export default function PlayerVideoGallery({ gallery = [], uploadVideo, uploads, loading, error }) {
    const [openLightbox, setOpenLightbox] = useState(false);
    const [selectedImage, setSelectedImage] = useState(0);
    const imagesLightbox = gallery.map((img) => img.imageUrl);

    return (
        <Box sx={{ mt: 5 }}>
            {!error &&
                !loading &&
                uploads !== null &&
                uploads !== undefined &&
                uploads.length === 0 && (
                    <>
                        <Container sx={{ mt: -30 }} component={MotionContainer}>
                            <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
                                <m.div variants={varBounce().in}>
                                    <Typography variant="h3" paragraph>
                                        Oops!
                                    </Typography>
                                </m.div>

                                <m.div variants={varBounce().in}>
                                    <Typography sx={{ color: "text.secondary", mt: 2, mb: 4 }}>
                                        Sorry, we couldn’t find any gameplay uploads associated with
                                        this profile.
                                    </Typography>
                                </m.div>

                                <Button
                                    to="/dashboard/uploads/new"
                                    size="large"
                                    variant="contained"
                                    onClick={() => uploadVideo()}
                                >
                                    Create a New Upload
                                </Button>
                            </ContentStyle>
                        </Container>
                    </>
                )}

            {error && !loading && (
                <>
                    <Container sx={{ mt: -30 }} component={MotionContainer}>
                        <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
                            <m.div variants={varBounce().in}>
                                <Typography variant="h3" paragraph>
                                    Oops!
                                </Typography>
                            </m.div>

                            <m.div variants={varBounce().in}>
                                <Typography sx={{ color: "text.secondary", mt: 2, mb: 4 }}>
                                    We encountered an error fetching gameplay uploads for this
                                    profile. Please try again.
                                </Typography>
                            </m.div>

                            <Button
                                size="large"
                                variant="contained"
                                onClick={() => window.location.reload()}
                            >
                                Reload
                            </Button>
                        </ContentStyle>
                    </Container>
                </>
            )}

            {uploads !== null &&
                uploads !== undefined &&
                uploads.length > 0 &&
                !loading && (
                    <Card sx={{ p: 3 }}>
                        <Box
                            sx={{
                                display: "grid",
                                gap: 3,
                                gridTemplateColumns: {
                                    xs: "repeat(1, 1fr)",
                                },
                            }}
                        >
                            {uploads.map((upload) => (
                                <GalleryItem video={upload} />

                            ))}
                        </Box>

                        <LightboxModal
                            images={imagesLightbox}
                            mainSrc={imagesLightbox[selectedImage]}
                            photoIndex={selectedImage}
                            setPhotoIndex={setSelectedImage}
                            isOpen={openLightbox}
                            onCloseRequest={() => setOpenLightbox(false)}
                        />
                    </Card>
                )}
            {loading && (
                <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                </Stack>
            )}
        </Box>
    );
}

GalleryItem.propTypes = {
    image: PropTypes.object,
    onOpenLightbox: PropTypes.func,
};

function GalleryItem({ video }) {

    return (
        <Card sx={{ position: "relative" }}>
            <Grid container>
                <Grid item xs={12} md={6} lg={7}>
                    <UploadMediaContent upload={video} videoUrl={video.videoUrl} />
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                    <UploadDetailsSummary videoUrl={video.videoUrl} upload={video} />
                </Grid>
            </Grid>
            <CaptionStyle>
                <div></div>
                <Stack direction={"row"} alignItems={"center"}>
                    {video.featured && <Iconify icon={"twemoji:star"} width={20} height={20} />}
                </Stack>
            </CaptionStyle>
        </Card>
    );
}

import  { useEffect, useState } from 'react'
import { PlayerService } from 'src/services/player';
import useAuth from './useAuth';

const usePlayer = (id) => {
    const [player, setPlayer] = useState()
    const [isFetching, setIsFetching] = useState(true);
    const {user} = useAuth();

    useEffect(() => {
        fetchPlayerProfile(id)
         // eslint-disable-next-line
    }, [id])


    const fetchPlayerProfile = async () => {
        try {
            setIsFetching(true)
            const player = await PlayerService.getManagedPlayerById(user._id, id);
            setPlayer(player)
            setIsFetching(false)
        } catch (error) {
            setIsFetching(false)
            throw new Error("Failed to fetch player profile : ", id)
        }
    }

    const refetch = async () => {
        try {
            const player = await PlayerService.getManagedPlayerById(user._id, id);
            setPlayer(player)
        } catch (error) {
            throw new Error("Failed to fetch player profile : ", id)
        }

    }

    return {
        player,
        isFetching,
        refetch: refetch
    }
}

export default usePlayer
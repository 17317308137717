import { Card, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Label from "src/components/Label";
import Markdown from "src/components/Markdown";
import UploadMediaContent from "src/sections/@dashboard/uploads/UploadMediaContent";

const PlayerProfileBio = ({ profile, videos }) => {
  return (
    <>
      <Card sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 1 }}>
          Bio
        </Typography>
        <Markdown children={profile?.bio} />
      </Card>
      {videos.map((video) => (
        <Card key={video}>
          <Grid container>
            <Grid item xs={12} md={6} lg={6}>
              <UploadMediaContent upload={video} videoUrl={video.videoUrl} />
            </Grid>
            <Grid item xs={12} md={6} lg={6} paddingTop={1.5} paddingLeft={1.5}>
              <Typography variant="h5">{video.customName}</Typography>
              <Stack direction={"row"} spacing={1} paddingTop={1} alignItems={"center"}>
                {video.game !== null && (
              <Label
                variant={"filled"}
                color="warning"
                sx={{ mr: 1, textTransform: "capitalize" }}
              >
                {video.game}
              </Label>
            )}
                {video?.gameUserName &&<Label
                variant={"filled"}
                color="info"
                sx={{  }}
              >
                {"@" + video?.gameUserName}
              </Label>}
                
              </Stack>
              {video.description && (
                <Stack justifyContent="space-between" sx={{ my: 1 }}>
                  <Typography
                    variant="body1"
                    sx={{ mt: 0.5 }}
                    dangerouslySetInnerHTML={{ __html: video.description }}
                  ></Typography>
                </Stack>
              )}
            </Grid>
          </Grid>
        </Card>
      ))}
    </>
  );
};

export default PlayerProfileBio;

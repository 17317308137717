import { Stack, Typography } from '@mui/material'
import React from 'react'
import { RHFEditor } from 'src/components/hook-form'

const Description = ({ name, title, subtitle }) => {
    return (
        <>
            <Typography variant="h6" sx={{ fontWeight: "500", mb: 2, ml: 1 }}>
                {title}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, ml: 1 }}>
                {subtitle}
            </Typography>
            <Stack spacing={3} alignItems="flex-end" sx={{ my: 3 }}>
                <RHFEditor name={name} label={"Description"} />
            </Stack></>
    )
}

export default Description
/* eslint-disable no-unused-vars */
import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";
import MainLayout from "src/layouts/main";
import PlayerProfile from "src/pages/dashboard/PlayerProfile";
import OrganizationProfile from "src/pages/dashboard/OrganizationProfile";
import MyProspects from "src/pages/dashboard/MyProspects";
import PlayersIManage from "src/pages/dashboard/PlayersIManage";
import Search from "src/pages/dashboard/Search";
import PlayerView from "src/pages/dashboard/ManagePlayerView";
import PublicPlayerProfile from "src/pages/dashboard/public/profile";
import PublcOrganizationProfile from "src/pages/dashboard/public/organization/profile";
import TeamView from "../pages/dashboard/ManageTeamView";
import PublicTeamView from "src/pages/dashboard/public/team/profile";
import UserPublicProfile from "src/pages/dashboard/public/user/profile";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "new-password", element: <NewPassword /> },
        { path: "verify", element: <VerifyCode /> },
        {
          path: "register-invite",
          element: (
            <GuestGuard>
              <RegisterViaInvite />
            </GuestGuard>
          ),
        },
      ],
    },
    { path: "terms-of-service", element: <TermsAndService /> },
    { path: "privacy-policy", element: <PrivacyPolicy /> },

    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "app", element: <GeneralApp /> },


        {
          path: "uploads",
          children: [
            {
              element: <Navigate to={PATH_AFTER_LOGIN} replace />,
              index: true,
            },
            { path: "new", element: <EcommerceProductCreate /> },
          ],
        },
        {
          path: "public",
          children: [
            {
              path: "player/:id", element: <PublicPlayerProfile />
            },
            {
              path: "organization/:id", element: <PublcOrganizationProfile/>
            },
            {
              path: "team/:id", element: <PublicTeamView/>
            },
            {
              path: "user/:id", element: <UserPublicProfile/>
            }
          ]
        },
        {
          path: "player",
          children: [
            {
              element: <PlayerProfile />,
              index: true,
            },
            { path: "team/:id", element: <PlayerProfileTeamView /> },
          ],
        },
        {
          path: "organization",
          children: [{
            children: [
              {
                element: <OrganizationProfile />,
                index: true,
              },
            ],
          },
          { path: "team/:id", element: <ManageTeamView /> }],
        },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: "profile", element: <UserProfile /> },
            {
              path: ":uid/uploads/:upid",
              element: <UserProfileUploadViewById />,
            },
            // { path: 'cards', element: <UserCards /> },
            // { path: 'list', element: <UserList /> },
            { path: "new", element: <UserCreate /> },
            { path: ":name/edit", element: <UserCreate /> },
            { path: "account", element: <UserAccount /> },
          ],
        },
        {
          path: "invoice",
          children: [
            {
              element: <Navigate to="/dashboard/invoice/list" replace />,
              index: true,
            },
            { path: "list", element: <InvoiceList /> },
            { path: ":id", element: <InvoiceDetails /> },
            { path: ":id/edit", element: <InvoiceEdit /> },
            { path: "new", element: <InvoiceCreate /> },
          ],
        },

        {
          path: "teams",
          children: [
            { element: <ManageTeams />, index: true },
            { path: "new", element: <CreateTeamForm /> },
            { path: ":id", element: <ManageTeamView /> },
          ],
        },
        {
          path: "squads",
          children: [
            { element: <MyProspects />, index: true },
            { path: "new", element: <SquadCreateForm /> },
            { path: ":id", element: <ManageSquadView /> },
          ],
        },
        {
          path: "players",
          children: [
            { element: <PlayersIManage />, index: true },
            { path: ":id", element: <PlayerView /> }
          ],

        },
        {
          path: "search",
          children: [
            { element: <Search />, index: true },
          ],

        },

        { path: "permission-denied", element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "coming-soon", element: <ComingSoon /> },
        { path: "maintenance", element: <Maintenance /> },
        { path: "pricing", element: <Pricing /> },
        { path: "payment", element: <Payment /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: (
        <GuestGuard>
          <MainLayout></MainLayout>
        </GuestGuard>
      ),
      children: [{ path: "", element: <HomePage /> }],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const NewPassword = Loadable(lazy(() => import("../pages/auth/NewPassword")));
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));
const RegisterViaInvite = Loadable(
  lazy(() => import("../pages/auth/RegisterViaInvite"))
);

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);
const GeneralEcommerce = Loadable(
  lazy(() => import("../pages/dashboard/GeneralEcommerce"))
);
const GeneralAnalytics = Loadable(
  lazy(() => import("../pages/dashboard/GeneralAnalytics"))
);
const GeneralBanking = Loadable(
  lazy(() => import("../pages/dashboard/GeneralBanking"))
);
const GeneralBooking = Loadable(
  lazy(() => import("../pages/dashboard/GeneralBooking"))
);

// ECOMMERCE
const EcommerceShop = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceShop"))
);
const EcommerceProductDetails = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceProductDetails"))
);
const EcommerceProductList = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceProductList"))
);
const EcommerceProductCreate = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceProductCreate"))
);
const EcommerceCheckout = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceCheckout"))
);

const SquadCreateForm = Loadable(
  lazy(() =>
    import(
      "src/sections/@dashboard/user/account/SquadAccountGeneral/SquadCreateForm"
    )
  )
);

// INVOICE
const InvoiceList = Loadable(
  lazy(() => import("../pages/dashboard/InvoiceList"))
);
const InvoiceDetails = Loadable(
  lazy(() => import("../pages/dashboard/InvoiceDetails"))
);
const InvoiceCreate = Loadable(
  lazy(() => import("../pages/dashboard/InvoiceCreate"))
);
const InvoiceEdit = Loadable(
  lazy(() => import("../pages/dashboard/InvoiceEdit"))
);

// BLOG
const BlogPosts = Loadable(lazy(() => import("../pages/dashboard/BlogPosts")));
const BlogPost = Loadable(lazy(() => import("../pages/dashboard/BlogPost")));
const BlogNewPost = Loadable(
  lazy(() => import("../pages/dashboard/BlogNewPost"))
);

// Manage Teams
const ManageTeams = Loadable(
  lazy(() => import("../pages/dashboard/ManageTeams"))
);
const ManageTeamView = Loadable(
  lazy(() => import("../pages/dashboard/ManageTeamView"))
);
const PlayerProfileTeamView = Loadable(
  lazy(() => import("../pages/dashboard/PlayerProfileTeamView"))
);
const ManageSquadView = Loadable(
  lazy(() => import("../pages/dashboard/ManageSquadView"))
);

// USER
const UserProfile = Loadable(
  lazy(() => import("../pages/dashboard/UserProfile"))
);
const UserProfileUploadViewById = Loadable(
  lazy(() => import("../sections/@dashboard/uploads/UploadViewById"))
);
const UserCards = Loadable(lazy(() => import("../pages/dashboard/UserCards")));
const UserList = Loadable(lazy(() => import("../pages/dashboard/UserList")));
const UserAccount = Loadable(
  lazy(() => import("../pages/dashboard/UserAccount"))
);
const UserCreate = Loadable(
  lazy(() => import("../pages/dashboard/UserCreate"))
);

// APP
const Chat = Loadable(lazy(() => import("../pages/dashboard/Chat")));
const Mail = Loadable(lazy(() => import("../pages/dashboard/Mail")));
const Calendar = Loadable(lazy(() => import("../pages/dashboard/Calendar")));
const Kanban = Loadable(lazy(() => import("../pages/dashboard/Kanban")));

const CreateTeamForm = Loadable(
  lazy(() =>
    import(
      "src/sections/@dashboard/user/account/TeamAccountGeneral/TeamAccountCreateForm"
    )
  )
);
// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(
  lazy(() => import("../pages/dashboard/PermissionDenied"))
);

// MAIN
const HomePage = Loadable(lazy(() => import("../pages/Home")));
const PrivacyPolicy = Loadable(lazy(() => import("../pages/PrivacyPolicy")));
const TermsAndService = Loadable(
  lazy(() => import("../pages/TermsAndService"))
);
const About = Loadable(lazy(() => import("../pages/About")));
const Contact = Loadable(lazy(() => import("../pages/Contact")));
const Faqs = Loadable(lazy(() => import("../pages/Faqs")));
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));
const Pricing = Loadable(lazy(() => import("../pages/Pricing")));
const Payment = Loadable(lazy(() => import("../pages/Payment")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const Page403 = Loadable(lazy(() => import("../pages/Page403")));
const Page404 = Loadable(lazy(() => import("../pages/Page404")));

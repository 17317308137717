import PropTypes from 'prop-types';
// @mui
import { Grid, Stack } from '@mui/material';


// ----------------------------------------------------------------------

Profile.propTypes = {
  myProfile: PropTypes.object,
  posts: PropTypes.array,
};

export default function Profile({ leftNav, main }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Stack spacing={3}>
          {leftNav}
        </Stack>
      </Grid>

      <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          {main}
        </Stack>
      </Grid>
    </Grid>
  );
}

import { useEffect, useState } from 'react'
import { OrganisationService } from 'src/services/organisation';
import useAuth from './useAuth';

const usePublicOrganization = (id) => {
    const { user } = useAuth()
    const [profile, setProfile] = useState();
    const [fetching, setIsFetching] = useState(true);
    useEffect(() => {

        fetchProfile(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?._id])



    const fetchProfile = async (id) => {
        try {

            const profile = await OrganisationService.getPublicOrganizationProfileById(id);
            if (profile) {
                if (profile.avatarUrl) {
                    localStorage.setItem("organizationProfileImage", profile.avatarUrl)
                }
                setProfile(profile)
            }
        } catch (error) {
        }
        setIsFetching(false)
    }

    return {
        fetching,
        profile,
        refetch: fetchProfile
    }
}

export default usePublicOrganization
import { useParams } from "react-router";
import { useMemo } from "react";
// @mui
import { styled } from "@mui/material/styles";
import { Tab, Box, Card, Tabs, Container } from "@mui/material";
// hooks
import useTabs from "src/hooks/useTabs";
import useSettings from "src/hooks/useSettings";
// components
import Page from "src/components/Page";
import Iconify from "src/components/Iconify";
// sections
//utils
import Loader from "./Loader";
import usePlayer from "src/hooks/usePlayer";
import PlayerCover from "src/sections/@dashboard/manage/ManagedPlayerView/PlayerCover";
import ManagedPlayerView from "src/sections/@dashboard/manage/ManagedPlayerView";


// ----------------------------------------------------------------------

const TabsWrapperStyle = styled("div")(({ theme }) => ({
    zIndex: 9,
    bottom: 0,
    width: "100%",
    display: "flex",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("sm")]: {
        justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
        justifyContent: "flex-end",
        paddingRight: theme.spacing(3),
    },
}));

// ----------------------------------------------------------------------

export default function PlayerView() {
    const { themeStretch } = useSettings();
    const { id } = useParams();
    const { currentTab, onChangeTab } = useTabs("Player Details");
    const { player, isFetching, refetch } = usePlayer(id)

    const PROFILE_TABS = useMemo(() => {
        return [
            {
                value: "Player Details",
                icon: <Iconify icon={"jam:gamepad"} width={20} height={20} />,
                component: <Loader loading={isFetching}><ManagedPlayerView {...player} refetch={refetch}  /></Loader>
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [player, isFetching]);


    return (
        <Page title="Manage Player">
            <Loader loading={isFetching}>
                <Container maxWidth={themeStretch ? false : "lg"}>
                    {/* <HeaderBreadcrumbs heading="Team" links={[]} /> */}
                    <Card
                        sx={{
                            mb: 3,
                            height: 280,
                            position: "relative",
                        }}
                    >
                        <PlayerCover
                            profile={player?.player.avatarUrl}
                            title={player?.player.userId?.username}

                            id={id}
                        />
                        <TabsWrapperStyle>
                            <Tabs
                                allowScrollButtonsMobile
                                variant="scrollable"
                                scrollButtons="auto"
                                value={currentTab}
                                onChange={onChangeTab}
                            >
                                {PROFILE_TABS.map((tab) => (
                                    <Tab
                                        disableRipple
                                        key={tab.value}
                                        value={tab.value}
                                        icon={tab.icon}
                                        label={tab.value}
                                    />
                                ))}
                            </Tabs>
                        </TabsWrapperStyle>
                    </Card>

                    {PROFILE_TABS.map((tab) => {
                        const isMatched = tab.value === currentTab;
                        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                    })}
                </Container>
            </Loader>
        </Page>
    );
}

import {
  Card,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Avatar from "src/components/Avatar";

const Games = ({ games = [] }) => {
  return (
    <Card>
      <CardHeader title="Games Played" />
      <Stack sx={{ p: 3 }}>
        <Grid container direction={"row"}>
          {games.map(({ _id, name }) => (
            <Grid
              container
              alignItems={"center"}
              flex
              flexDirection={"row"}
              gap={1.5}
              sm={6}
              sx={{ mb: 2 }}
              key={_id}
            >
              <Grid item>
                <Avatar src="" name={name}/>
              </Grid>
              <Grid item>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <Typography variant="subtitle1">{name}</Typography>
                </Stack>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Card>
  );
};

export default Games;

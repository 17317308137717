import { Autocomplete, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

const GamesPlayed = ({ games, control , name }) => {
    return (
        <>
            <Typography variant="h6" sx={{ fontWeight: "500", mb: 1, ml: 1 }}>
                Games
            </Typography>
            <Controller
                name={name}
                control={control}
                render={({ field: gamesField, fieldState: { error } }) => (
                    <Autocomplete
                        multiple
                        limitTags={1}
                        id="multiple-limit-tags"
                        options={games}
                        {...gamesField}
                        getOptionLabel={(tag) => {
                            return tag?.name
                        }}

                        getOptionKey={tag => tag?._id}
                        onChange={(_, data) => {
                            const values = Array.from(new Set(data.map(item => item._id)))
                                .map(_id => {
                                    return data.find(item => item._id === _id);
                                });
                            gamesField.onChange(values)
                        }
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Games"
                                error={!!error}
                                helperText={error?.message || ""}
                            />
                        )}
                    />
                )}
            ></Controller>
        </>
    )
}

export default GamesPlayed
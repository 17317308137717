import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import { useMemo } from "react";
// @mui
import { styled } from "@mui/material/styles";
import { Tab, Box, Card, Tabs, Container } from "@mui/material";
// hooks
import useTabs from "src/hooks/useTabs";
import useSettings from "src/hooks/useSettings";
import useTeam from "src/hooks/useTeam";
// components
import Page from "src/components/Page";
import Iconify from "src/components/Iconify";
// sections
import TeamProfile from "src/sections/@dashboard/manage/TeamView";
import TeamCover from "src/sections/@dashboard/manage/TeamView/TeamCover";
import ManageMembers from "src/sections/@dashboard/manage/Members";
import TeamAccountForm from "src/sections/@dashboard/user/account/TeamAccountGeneral";
//utils
import Loader from "./Loader";
import JoinTeamRequests from "src/sections/@dashboard/user/profile/MyPlayerProfile/JoinTeamRequests";
import useAuth from "src/hooks/useAuth";

// ----------------------------------------------------------------------

const TabsWrapperStyle = styled("div")(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: "100%",
  display: "flex",
  position: "absolute",
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up("sm")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.up("md")]: {
    justifyContent: "flex-end",
    paddingRight: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

export default function TeamView() {
  const { themeStretch } = useSettings();
  const location = useLocation();
  const { user } = useAuth();

  const { id } = useParams();
  const { currentTab, onChangeTab, setCurrentTab } = useTabs("Details");

  const isTeamManagedByUser = location.pathname.startsWith("/dashboard/teams");

  const organizationActive = user.goals.isCollegeTeamRecruitingPlayers && user.isVerifiedCollegeRep

  const { team,
    submit,
    fetching,
    deleteTeam,
    organization,
    invites,
    handleAccept,
    handleReject,
    handleRevoke,
    refetch,
    joins } = useTeam(id, !isTeamManagedByUser);

  const PROFILE_TABS = useMemo(() => {
    return [
      {
        value: "Details",
        icon: <Iconify icon={"jam:gamepad"} width={20} height={20} />,
        component: <TeamProfile fetching={fetching} team={team} />,
      },
      {
        value: "Team Members",
        icon: (
          <Iconify
            icon={"fluent:people-team-24-filled"}
            width={20}
            height={20}
          />
        ),
        component: <ManageMembers fetching={fetching} team={team}  refetch={refetch}/>,
      },
      {
        value: "Pending Team Invites",
        icon: (
          <Iconify
            icon={"mdi:account-pending-outline"}
            width={20}
            height={20}
          />
        ),
        component: <Loader loading={fetching}>
          <JoinTeamRequests requests={invites} handleRevoke={handleRevoke} handleAccept={handleAccept} handleReject={handleReject} label={"Pending Team Invites"} searchLabel={"Search invites..."} />
        </Loader>,
      },
      {
        value: "Team Join Requests",
        icon: (
          <Iconify
            icon={"fluent:ribbon-add-24-filled"}
            width={20}
            height={20}
          />
        ),
        component: <Loader loading={fetching}>
          <JoinTeamRequests requests={joins} handleAccept={handleAccept} handleReject={handleReject} label={"Team Join Requests"} searchLabel={"Search join requests..."} />
        </Loader>,
      },
      isTeamManagedByUser && {
        value: "Edit",
        icon: <Iconify icon={"flowbite:edit-solid"} width={20} height={20} />,
        component: !fetching ? (
          <TeamAccountForm
            deleteTeam={isTeamManagedByUser ? deleteTeam : null}
            organizationActive={organizationActive}
            profile={team}
            submit={submit}
            navigate={() => setCurrentTab("Details")}
            title={"Edit Team"}
          />
        ) : null,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team, fetching, organization, joins, invites]);

  return (
    <Page title="Manage Team">
      <Loader loading={fetching}>
        <Container maxWidth={themeStretch ? false : "lg"}>
          {/* <HeaderBreadcrumbs heading="Team" links={[]} /> */}
          <Card
            sx={{
              mb: 3,
              height: 280,
              position: "relative",
            }}
          >
            <TeamCover
              profile={team?.avatarUrl}
              title={team?.name?.trim() || "No Title"}
              subtitle={
                !!team?.linkedOrganization && team?.linkedOrganization.name
                  ? team.linkedOrganization.name
                  : ""
              }
              id={id}
            />
            <TabsWrapperStyle>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={currentTab}
                onChange={onChangeTab}
              >
                {PROFILE_TABS.map((tab) => (
                  <Tab
                    disableRipple
                    key={tab.value}
                    value={tab.value}
                    icon={tab.icon}
                    label={tab.value}
                  />
                ))}
              </Tabs>
            </TabsWrapperStyle>
          </Card>

          {PROFILE_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Container>
      </Loader>
    </Page>
  );
}

import { useEffect, useMemo } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Box,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FormProvider, RHFEditor, RHFSelect } from "../hook-form";
import { Controller, useForm, useFormContext } from "react-hook-form";
import useGames from "src/hooks/useGames";
import { PlayerService } from "src/services/player";
import { useSnackbar } from "notistack";
import { Validation } from "src/utils/validations";
import { yupResolver } from "@hookform/resolvers/yup";

export default function AddToTeamDialog({
  open,
  handleClose,
  teamsList,
  player,
  refetch,
}) {
  const { games } = useGames();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    selectedTeam: "",
    games: [],
    inviteMessage: "",
  };

  const methods = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(Validation.AddToTeamInviteValidation),
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (open) {
      reset(); // Reset form fields when dialog is opened
    }
  }, [open, reset]);

  const onSubmit = async (data) => {
    try {
      const { inviteMessage, games: positionsList, selectedTeam } = data;
      const filteredPositions = positionsList.filter(({ game }) => game);
      await PlayerService.invitePlayerToTeam(selectedTeam, player._id, {
        positionsList: filteredPositions,
        inviteMessage,
      });
      enqueueSnackbar("Player invited to team successfully.", {
        variant: "success",
        autoHideDuration: 4000,
      });
      handleClose();
      refetch();
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Failed to invite player to team.", {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xl"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Invite Player to Team</DialogTitle>
        <DialogContent>
          <Stack>
            <Box sx={{ mt: 2 }}>
              <RHFSelect name={`selectedTeam`} label="Team">
                <optgroup>
                  <option key={"None"} value={""}></option>
                  {teamsList.map((team) => (
                    <option key={team._id} value={team._id}>
                      {team.name}
                    </option>
                  ))}
                </optgroup>
              </RHFSelect>
            </Box>
            <Box sx={{ my: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: "500", ml: 1 }}>
                Select Games
              </Typography>
              <GameOptions games={games} />
            </Box>
            <Box>
              <Typography variant="h6" sx={{ fontWeight: "500", mb: 1, ml: 1 }}>
                Invitation Text
              </Typography>
              <RHFEditor name="inviteMessage" label="Invite" />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}

export const GameOptions = ({ games }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={"games"}
      control={control}
      render={({ field, fieldState }) => (
        <>
          <Box
            sx={{
              display: "grid",
              rowGap: 3,
              columnGap: 2,
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(3, 1fr)",
              },
              mt: 2,
            }}
          >
            <>
              {field.value.map((value, index) => (
                <GameOptionSelector
                  field={field}
                  games={games}
                  index={index}
                  key={index}
                  control={control}
                  {...fieldState}
                />
              ))}
            </>
          </Box>
          {fieldState.error?.message && (
            <Typography sx={{ color: "error.main", mt: 1 }}>
              {fieldState.error?.message}
            </Typography>
          )}
          <Stack
            alignContent="center"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="success"
              sx={{ mt: 2, color: "white" }}
              onClick={(e) =>
                field.onChange([
                  ...field.value,
                  {
                    game: "",
                    positions: [],
                  },
                ])
              }
            >
              {field.value.length ? "Add another game" : "Add a game"}
            </Button>
          </Stack>
        </>
      )}
    ></Controller>
  );
};

const GameOptionSelector = ({ games, field, index, control, error }) => {
  const options = useMemo(() => {
    return (
      games.find(({ _id }) => field.value[index]?.game === _id)?.positions || []
    );
  }, [field.value, games, index]);

  const handleChange = (key, e) => {
    let tempValues = [...field.value];
    tempValues[index] = { ...tempValues[index], [key]: e };
    field.onChange(tempValues);
  };

  const handleRemove = () => {
    const filteredGames = field.value.filter((game, i) => i !== index);
    field.onChange(filteredGames);
  };

  return (
    <Box
      sx={{
        display: "grid",
        rowGap: 2,
        columnGap: 1,
        gridTemplateColumns: { xs: "repeat(1, 1fr)" },
      }}
    >
      <RHFSelect
        name={`games[${index}].game`}
        label="Game"
        onChange={(e) => {
          handleChange("game", e.target.value);
        }}
        value={field.value[index].name}
      >
        <optgroup>
          <option key={"None"} value={""}></option>
          {games.map((game) => (
            <option key={game._id} value={game._id}>
              {game.name}
            </option>
          ))}
        </optgroup>
      </RHFSelect>
      <Controller
        name={`games[${index}].positions`}
        control={control}
        render={({ field: positionsField }) => (
          <Autocomplete
            multiple
            limitTags={2}
            {...positionsField}
            options={options}
            getOptionLabel={(option) => option}
            defaultValue={[]}
            renderInput={(params) => (
              <TextField
                error={!!error?.[index]}
                helperText={error?.[index]?.positions?.message || ""}
                {...params}
                label="Positions"
                name={`games[${index}].postions`}
              />
            )}
            onChange={(_, data) => positionsField.onChange(data)}
          />
        )}
      />

      <Stack alignContent="center" justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          sx={{ width: "50%", maxWidth: 158, maxHeight: 35 }}
          color="error"
          onClick={handleRemove}
        >
          Remove
        </Button>
      </Stack>
    </Box>
  );
};

import {
  Box,
  Card,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { RHFTextField, RHFUploadSingleFile } from "src/components/hook-form";
import { Controller } from "react-hook-form";

const PrerollCard = ({ values, control }) => {
  return (
    <Card sx={{ py: 4, px: 3, textAlign: "left" }}>
      <Typography variant="h6" sx={{ fontWeight: "500", mb: 2, ml: 1 }}>
        Pre-Roll Video
      </Typography>
      <Typography variant="body1" sx={{ mb: 2, ml: 1 }}>
        If your organization has a pre-roll video, please upload it here. This
        video can introduce your organization, highlight your achievements, or
        provide important information to potential recruits. Accepted formats
        are MP4, AVI, and MOV. Alternatively, you can provide a YouTube link to
        the video.
      </Typography>
      <Controller
        name={"videoUpload"}
        control={control}
        render={({ field }) => (
          <>
            <RadioGroup
              row
              sx={{ ml: 1 }}
              {...field}
              value={field.value}
              onChange={(_, value) => {
                field.onChange(value === "true");
              }}
            >
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Youtube Link"
              />
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Upload"
              />
            </RadioGroup>
            <Box sx={{ mt: 3 }}>
              {values.videoUpload ? (
                <UploadForm control={control} />
              ) : (
                <RHFTextField name="youtubeLink" label="Youtube Link" />
              )}
            </Box>
          </>
        )}
      />
    </Card>
  );
};

export default PrerollCard;

const UploadForm = ({ control }) => {
  const handleDropUpload = (acceptedFiles, field) => {
    let file = acceptedFiles[0];

    if (file) {
      if (file.type.includes("video/")) {
        file = Object.assign(file, {
          preview: "/assets/videofileuploaded.jpg",
        });
      } else {
        file = Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      }
      field.onChange(file);
    }
  };

  return (
    <Controller
      name={"videoFile"}
      control={control}
      render={({ field, fieldState }) => (
        <>
          <RHFUploadSingleFile
            name={"videoFile"}
            value={field.value}
            maxSize={25000000} //25 MB
            accept="video/mp4, video/mpeg, video/quicktime, video/x-msvideo,"
            onDrop={(acceptedFiles) => {
              handleDropUpload(acceptedFiles, field);
            }}
          ></RHFUploadSingleFile>
          <Typography sx={{ mt: 1 }}>Max upload size: 25 MB</Typography>
        </>
      )}
    />
  );
};

import { Card, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import React from 'react'
import { Controller } from 'react-hook-form';
import { RHFEditor } from 'src/components/hook-form';

const ScholarshipCard = ({ values, control }) => {
    return (
        <Card sx={{ p: 3, mt: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: "500", mb: 2, ml: 1 }}>
                Scholarships Available
            </Typography>
            <Controller
                name={"scholarshipsOffered"}
                control={control}
                render={({ field }) => (
                    <>
                        <RadioGroup
                            row
                            {...field}
                            value={field.value}
                            onChange={(_, value) => {
                                field.onChange(value === "true")
                            }}
                            sx={{ ml: 1 }}
                        >
                            <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="No"
                            />
                            <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Yes"
                            />
                        </RadioGroup>
                    </>

                )} />
            {values.scholarshipsOffered && (
                <>
                    <Typography
                        variant="h6"
                        sx={{ fontWeight: "500", mt: 3, mb: 2, ml: 1 }}
                    >
                        Scholarship Details
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2, ml: 1 }}>
                        Detail the scholarships your organization offers. Include
                        information on eligibility criteria, application deadlines,
                        award amounts, and any specific requirements or benefits
                        associated with each scholarship.
                    </Typography>
                    <Stack spacing={3} alignItems="flex-end" sx={{ my: 3 }}>
                        <RHFEditor
                            name="scholarshipsOfferedText"
                            label={"Scholarship Details"}
                        />
                    </Stack>
                </>
            )}
        </Card>
    )
}

export default ScholarshipCard
// @mui
import {
  Container,
  Typography,
  Grid,
} from "@mui/material";
// routes
import useSettings from "../../../../hooks/useSettings";
// components
import Page from "../../../../components/Page";
import HeaderBreadcrumbs from "../../../../components/HeaderBreadcrumbs";
// sections
import { TeamCard } from "src/sections/@dashboard/manage";
import { SkeletonPostItem } from "src/components/skeleton";

// ----------------------------------------------------------------------

export default function FeaturedTeams({ featuredTeams}) {
  const { themeStretch } = useSettings();

  return (
    <Page title="Featured Teams">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs sx={{ mt: 4, mb: 1 }} heading="Featured Teams" />

        <Grid container spacing={3}>
          {featuredTeams?.length
            ? featuredTeams?.map((team, index) =>
                team ? (
                  <Grid key={team._id} item xs={12} sm={6} md={4}>
                    <TeamCard team={team} />
                  </Grid>
                ) : (
                  <SkeletonPostItem key={index} />
                )
              )
            : ""}
        </Grid>

        

        {!featuredTeams?.length && (
          <Typography variant="subtitle1" sx={{ textAlign: "center", mt: 2 }}>
             This organization does not have any featured teams.
          </Typography>
        )}
      </Container>
    </Page>
  );
}

import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Card, CardHeader, Stack } from '@mui/material';
// components
import { useMemo } from 'react';

import Link from '@mui/material/Link';
import Iconify from 'src/components/Iconify';
// ----------------------------------------------------------------------

const IconStyle = styled(Iconify)(({ theme }) => ({
    width: 20,
    height: 20,
    marginTop: 1,
    flexShrink: 0,
    marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------

Socials.propTypes = {
    profile: PropTypes.object,
};

export default function Socials({ socials  = {}}) {
    const { facebook = "", instagram = "", threads = "", twitter = "" } = socials 

    const SOCIALS = useMemo(() => {
        return [
            {
                name: 'Linkedin',
                icon: <IconStyle
                    icon={"mingcute:threads-fill"}
                    color="#ffffff"
                />,
                href: threads,
            },
            {
                name: 'Twitter',
                icon: <IconStyle icon={"prime:twitter"} color="#1C9CEA" />,
                href: twitter,
            },
            {
                name: 'Instagram',
                icon: <IconStyle icon={'ant-design:instagram-filled'} color="#D7336D" />,
                href: instagram,
            },
            {
                name: 'Facebook',
                icon: <IconStyle icon={'eva:facebook-fill'} color="#1877F2" />,
                href: facebook,
            },
        ].filter(({ href }) => href)
    }, [facebook, threads, twitter, instagram]);


    if (!SOCIALS.length) {
        return null;
    }

    return (
        <Card>
            <CardHeader title="Socials" />
            <Stack spacing={2} sx={{ p: 3 }}>
                {SOCIALS.map((link) => (
                    <Stack key={link.name} direction="row" alignItems="center">
                        {link.icon}
                        <Link component="a" variant="body2" color="text.primary" noWrap href={link.href} target="_blank">
                            {link.href}
                        </Link>
                    </Stack>
                ))}
            </Stack>
        </Card>
    );
}

import useAuth from "../hooks/useAuth";
import Avatar from "./Avatar";

export default function MyAvatar({ profile, ...other }) {
  const { user } = useAuth();

  const profileImage = localStorage.getItem(profile);
  const fullName = localStorage.getItem("fullName");

  return (
    <Avatar
      src={profileImage}
      alt={user?.fullName}
      name={fullName}
      {...other}
    >
    </Avatar>
  );
}

import {  useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Typography } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import InviteUserForm from 'src/sections/auth/register/InviteUserForm';


const ITEM_HEIGHT = 64;



export default function ContactsPopover() {
  const [open, setOpen] = useState(null);
  const [hover, setHover] = useState(false)
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        color={open || hover  ? 'primary':'default'}
        onClick={handleOpen}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.secondary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Iconify sx={{color: "white"}} icon={'mdi:invite'} width={24} height={24} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 320,
          '& .MuiMenuItem-root': {
            px: 1.5,
            height: ITEM_HEIGHT,
            borderRadius: 0.75,
          },
        }}
      >
        <Typography variant="h6" sx={{ p: 1.5 }}>
          Invite Users
        </Typography>
        <Typography variant="p" sx={{ p: 1.5 }}>
          Invite a new user to join Ecruit.
        </Typography>

        <Scrollbar sx={{ height: ITEM_HEIGHT * 2.5, mt: 2 }}>
          <InviteUserForm />
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

import React from "react";
import { Alert, Card, CardHeader, Stack } from "@mui/material";
import Markdown from "src/components/Markdown";
import Games from "./Games";

const Timeline = ({ team }) => {
  return (
    <>
      <Games games={team?.gamesPlayedByTeam} />
      {team?.announcement && <Alert severity="info">{team.announcement}</Alert>}

      {team?.description && team?.description !== "<p><br></p>" && (
        <Card>
          <CardHeader title="Description" />
          <Stack sx={{ p: 3, pt: 1 }}>
            <Markdown>{team?.description}</Markdown>
          </Stack>
        </Card>
      )}

      {team?.privateNotes && team?.privateNotes !== "<p><br></p>" && (
        <Card>
          <CardHeader title="Private Notes" />
          <Stack sx={{ p: 3, pt: 1 }}>
            <Markdown>{team?.privateNotes}</Markdown>
          </Stack>
        </Card>
      )}
    </>
  );
};

export default Timeline;
